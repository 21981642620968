import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IAuthenticateRequestPayload } from '../models/auth';

export default ($axios: NuxtAxiosInstance) => ({
  authenticate(payload: IAuthenticateRequestPayload, redirectUri?: string) {
    let url = '/api/v1/identity/authenticate';

    if (redirectUri) {
      url = `${url}?redirect_uri=${redirectUri}`;
    }

    return $axios.post(url, payload);
  },
  signOut() {
    return $axios.post('/api/v1/identity/signout');
  },
});
