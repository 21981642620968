/* eslint-disable camelcase */
import { NuxtAppOptions } from '@nuxt/types/app';
import { extend, localize } from 'vee-validate';
import { required, alpha_dash, alpha_num } from 'vee-validate/dist/rules.umd';
import en from 'vee-validate/dist/locale/en.json';
import {
  startsWithSlash,
  endsWithSlash,
  repeatingSlash,
  moreThanTwoSlashes,
} from '../utils/helpers';
import { ISchemaListResponse } from '../models/schema';

export default ({ app, store }: { app: NuxtAppOptions; store: any }) => {
  localize({ en });

  extend('required', required);
  extend('alpha_dash', alpha_dash);
  extend('alpha_num', alpha_num);

  type ValidateValue = string | null | undefined;

  extend('uniqueSchemaAlias', {
    validate(value: ValidateValue): boolean {
      const schemas = <ISchemaListResponse[]>(
        (store?.getters['schema/schemas'] ?? [])
      );
      const schemaWithCollidingAlias = schemas.find(
        (schema) => schema.viewHandle === value,
      );
      if (schemaWithCollidingAlias) {
        return false;
      }

      return true;
    },
    message: `Schema alias is already in use`,
  });

  extend('startsWithSlash', {
    validate(value: ValidateValue): boolean {
      if (value) {
        return !startsWithSlash(value);
      }
      return false;
    },
    message: app.i18n.t('startsWithSlashValidationMessage').toString(),
  });

  extend('endsWithSlash', {
    validate(value: ValidateValue): boolean {
      if (value) {
        return !endsWithSlash(value);
      }
      return false;
    },
    message: app.i18n.t('endsWithSlashValidationMessage').toString(),
  });

  extend('repeatingSlash', {
    validate(value: ValidateValue): boolean {
      if (value) {
        return !repeatingSlash(value);
      }
      return false;
    },
    message: app.i18n.t('repeatingSlashValidationMessage').toString(),
  });

  extend('maxThreeSlashes', {
    validate(value: ValidateValue): boolean {
      if (value) {
        return !moreThanTwoSlashes(value);
      }
      return false;
    },
    message: app.i18n.t('maxThreeSlashesValidationMessage').toString(),
  });

  extend('hostname', {
    validate(value: ValidateValue): boolean {
      if (value) {
        return /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
          value,
        );
      }

      return false;
    },
    message: app.i18n.t('hostnameValidationMessage').toString(),
  });
};
