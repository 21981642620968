/* eslint-disable no-unused-vars */
export enum ButtonColors {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  WARNING = 'warning',
  ERROR = 'error',
  TRANSPARENT = 'transparent',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
}
