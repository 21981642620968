import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IManagementClientCreatePayload,
  IManagementClientEditPayload,
} from '../models/management-client';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/management-clients/');
  },
  create(payload: IManagementClientCreatePayload) {
    return $axios.post(`/api/v1/tenant/management-clients/`, payload);
  },
  edit(managementClientGuid: string, payload: IManagementClientEditPayload) {
    return $axios.put(
      `/api/v1/tenant/management-clients/${managementClientGuid}`,
      payload,
    );
  },
  delete(managementClientGuid: string) {
    return $axios.delete(
      `/api/v1/tenant/management-clients/${managementClientGuid}`,
    );
  },
  regenerateAccessKey(managementClientGuid: string) {
    return $axios.put(
      `/api/v1/tenant/management-clients/${managementClientGuid}/_regenerate`,
    );
  },
});
