var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"the-header"},[(_vm.isEnterspeedAdmin && _vm.viewingTenant)?_c('div',{staticClass:"the-header__warning"},[_vm._v("\n    "+_vm._s(_vm.$t('youAreCurrentlyViewing'))+"\n    "),_c('strong',[_vm._v(_vm._s(_vm.currentTenant.name))]),_vm._v(" "),_c('button',{staticClass:"the-header__end-button",attrs:{"type":"button"},on:{"click":_vm.endViewing}},[_c('svg-icon',{attrs:{"name":"close"}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"the-header__top"},[_c('div',{staticClass:"the-header__top-left"},[_c('the-logo',{staticClass:"the-header__logo"}),_vm._v(" "),(
          _vm.authIsValid && _vm.availableTenants.length && _vm.currentTenant !== null
        )?_c('div',{staticClass:"the-header__spacer"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"the-header__tenant"},[(
            _vm.authIsValid && _vm.availableTenants.length && _vm.currentTenant !== null
          )?_c('es-tenant-picker'):_vm._e(),_vm._v(" "),(_vm.availableTenants.length && _vm.currentTenant !== null)?_c('es-create-button',{staticClass:"the-header__create-tenant-button",attrs:{"tooltip-placement":"bottom","type":"tenant","button-size":"small","button-type":"normal","button-label":_vm.$t('newTenant'),"name":_vm.$t('tenant')}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"the-header__top-right"},[(_vm.$ld.ready && _vm.$ld.flags.commandbar && _vm.availableTenants.length)?_c('the-command-bar'):_vm._e(),_vm._v(" "),_c('the-dark-mode-button'),_vm._v(" "),(_vm.availableTenants.length)?_c('the-favorites'):_vm._e(),_vm._v(" "),_c('es-help-dropdown'),_vm._v(" "),_c('the-announcekit'),_vm._v(" "),(_vm.authIsValid)?_c('es-user-settings-dropdown'):_vm._e()],1)]),_vm._v(" "),(_vm.availableTenants.length)?_c('div',{staticClass:"the-header__bottom"},[_c('div',{staticClass:"the-header__bottom-left"},[_c('es-link',{staticClass:"the-header__menu-item",class:{
          'the-header__menu-item--active': _vm.navigation == 'homeNavigation',
        },attrs:{"to":"/","data-cy":"/"}},[_vm._v("\n        "+_vm._s(_vm.$t('home'))+"\n      ")]),_vm._v(" "),_c('es-link',{staticClass:"the-header__menu-item the-header__menu-item--sub",class:{
          'the-header__menu-item--active': _vm.navigation == 'versionsNavigation',
        },attrs:{"to":"/versions","data-cy":"/versions"}},[_vm._v("\n        "+_vm._s(_vm.$t('versions'))+"\n      ")]),_vm._v(" "),_c('es-link',{staticClass:"the-header__menu-item the-header__menu-item--sub",class:{
          'the-header__menu-item--active': _vm.navigation == 'settingsNavigation',
        },attrs:{"to":"/settings","data-cy":"/settings"}},[_vm._v("\n        "+_vm._s(_vm.$t('settings'))+"\n      ")]),_vm._v(" "),(_vm.isEnterspeedAdmin)?_c('es-link',{staticClass:"the-header__menu-item the-header__menu-item--sub",class:{
          'the-header__menu-item--active': _vm.navigation == 'adminNavigation',
        },attrs:{"to":"/admin","data-cy":"/admin"}},[_vm._v("\n        "+_vm._s(_vm.$t('admin'))+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"the-header__bottom-right"},[(_vm.currentTenant.isUsingSchemasBulkDeployment)?_c('es-button',{attrs:{"data-cy":"bulk-deploy-button","color":"primary","label":_vm.$t('deploySchemas')},on:{"click":_vm.openBulkDeployPane}}):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }