import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IRootState, ISchemaFoldersState } from '~/src/models/store';

export const state = (): ISchemaFoldersState => ({
  foldersStatus: [],
});

export const mutations: MutationTree<ISchemaFoldersState> = {
  setFoldersStatus(state, payload) {
    const folderStateIndex = state.foldersStatus.findIndex(
      (x: any) => x.folderPath === payload.folderPath,
    );
    if (folderStateIndex !== -1) {
      state.foldersStatus[folderStateIndex].isFolderOpen = payload.isFolderOpen;
    } else {
      state.foldersStatus.push({
        folderPath: payload.folderPath,
        isFolderOpen: payload.isFolderOpen,
      });
    }
  },
  resetFoldersStatus(state) {
    state.foldersStatus = [];
  },
};

export const getters: GetterTree<ISchemaFoldersState, IRootState> = {
  foldersStatus: (state) => state.foldersStatus,
};

export const actions: ActionTree<ISchemaFoldersState, IRootState> = {
  SET_SCHEMA_FOLDERS_STATUS({ commit }, foldersStatus) {
    commit('setFoldersStatus', foldersStatus);
  },
  RESET_SCHEMA_FOLDERS_STATUS({ commit }) {
    commit('resetFoldersStatus');
  },
};
