/* eslint-disable no-unused-vars */
export enum ModalSizes {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum AvatarSizes {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}
