/* eslint-disable no-unused-vars */
enum IconName {
  ATOM = 'atom',
  BACK = 'arrow-left',
  BELL = 'bell',
  CHAT = 'chat',
  CHART = 'chart',
  CHEVRON = 'chevron',
  CHECKMARK = 'checkmark',
  CLOSE = 'close',
  DANGER = 'danger',
  EYE = 'eye',
  EYEREGULAR = 'eye-regular',
  EYESLASHREGULAR = 'eye-slash-regular',
  FOLDER = 'folder',
  FOLDEROPEN = 'folder-open',
  INFO = 'info',
  LIST = 'list',
  SERVER = 'server',
  SETTINGS = 'settings',
  SETTINGS_DOTS = 'settings-dots',
  SWAP = 'swap',
  USER = 'user',
  USERPLUS = 'user-plus',
  USERSCOG = 'users-cog',
  USERS = 'users',
  REFRESH = 'refresh',
  TACHOMETER = 'tachometer',
  QUESTION = 'question',
  COG = 'cog',
  COGS = 'cogs',
  PLUSCIRCLE = 'pluscircle',
  SEARCH = 'search',
  SITEMAP = 'sitemap',
  HEART = 'heart',
  HEART_FILLED = 'heart-filled',
  MAGNIFYING_PLUS = 'magnifying-plus',
  ENVELOPE = 'envelope',
  SLIDERS_V = 'sliders-v',
  UNPUBLISH_SCHEMA = 'unpublish-schema',
  CIRCLE_EXCLAMATION = 'circle-exclamation',
  PLAY = 'play',
  TRASHCAN = 'trashcan',
  KEY = 'key',
  LIGHTBULBON = 'lightbulb-on',
  CHEVRONLEFT = 'chevron-left',
  CHEVRONRIGHT = 'chevron-right',
  PLUS = 'plus',
  FILE_ARROW_UP_LIGHT = 'file-arrow-up-light',
  CODE_LIGHT = 'code-light',
  GEAR_CODE_LIGHT = 'gear-code-light',
  LINK = 'link',
  BRACKETS_CURLY_SOLID = 'brackets-curly-solid',
  SQUARE_JS = 'square-js',
  CIRCLE_CHECK_SHARP_SOLID = 'circle-check-sharp-solid',
  EXTERNAL_LINK = 'external-link',
  COPY_REGULAR = 'copy-regular',
  SHUFFLE_REGULAR = 'shuffle-regular',
}

export default IconName;
