import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IApiResponse } from '../models/api';
import { IEnterspeedContext } from '../models/es-app';
import { IInvitesState, IRootState } from '../models/store';
import { ITenantInvite } from '../models/tenant';
import { IReceivedInvitation } from '../models/views';

const initialState: IInvitesState = {
  invites: [],
  loading: false,
};
export const state = (): IInvitesState => initialState;

export const mutations: MutationTree<IInvitesState> = {
  setInvites(state: IInvitesState, invites: ITenantInvite[]): void {
    state.invites = invites;
  },
  setInvitesLoading(state: IInvitesState, loading: boolean): void {
    state.loading = loading;
  },
};

export const getters: GetterTree<IInvitesState, IRootState> = {
  invites(state: IInvitesState): ITenantInvite[] {
    return state.invites;
  },
  loading(state: IInvitesState): boolean {
    return state.loading;
  },
};

export const actions: ActionTree<IInvitesState, IRootState> = {
  async GET_INVITES({ commit }: { commit: any; state: IInvitesState }) {
    commit('setInvitesLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const { data }: IApiResponse<ITenantInvite[]> =
      await context.$invitesApi.get();

    commit('setInvites', data);

    commit('setInvitesLoading', false);
  },

  async ACCEPT_INVITE(
    { commit, dispatch }: { commit: any; dispatch: any },
    invitation: IReceivedInvitation,
  ) {
    commit('setInvitesLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$invitesApi.acceptInvite(invitation.id);

      dispatch('auth/AUTHENTICATE', true, { root: true }).then(() => {
        dispatch('GET_INVITES');
      });
    } catch {
      commit('setInvitesLoading', false);
    }
  },

  async REJECT_INVITE(
    { commit, dispatch }: { commit: any; dispatch: any },
    id: string,
  ) {
    commit('setInvitesLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$invitesApi.declineInvite(id);

      dispatch('GET_INVITES');
    } catch {
      commit('setInvitesLoading', false);
    }
  },
};
