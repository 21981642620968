import { NuxtAxiosInstance } from '@nuxtjs/axios';
import qs from 'qs';
import {
  ITenantAdminPayload,
  ITenantGetAdminAllTenantsParams,
} from '../models/tenant';
import { IAdminUserSearchPayload } from '../models/users';

export default ($axios: NuxtAxiosInstance) => ({
  createAsAdmin(payload: ITenantAdminPayload) {
    return $axios.post('/api/v1/admin/tenants', payload);
  },

  getAsAdmin(params: ITenantGetAdminAllTenantsParams) {
    return $axios.get('/api/v1/admin/tenants', {
      params: {
        term: params.term,
        first: params.first,
        after: params.after,
      },
    });
  },

  searchUsersAsAdmin(payload: IAdminUserSearchPayload) {
    return $axios.get(`/api/v1/admin/users`, {
      params: {
        term: payload.term,
        first: payload.pagination.first,
        after: payload.pagination.after,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  },
});
