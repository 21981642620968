import { DryRunOptionAlias } from '../constants/ui/dryRun';
import { ModalSizes } from '../constants/ui/elementSizes';
import { IBulkDeploy } from './bulk-deploy';
import { IDomain } from './domain';
import { IDryRunOption } from './dry-run';
import { IEnvironmentClient } from './environment-client';
import { ISchemaDeployment, SchemaType } from './schema';
import ISelectOption from './select-input';
import { ISourceGroupsResponse } from './sourceGroups';
import { ITenant } from './tenant';

export enum ModalType {
  DELETE = 'delete',
  DEFAULT = 'default',
}
export interface IModalConfig<T> {
  title?: string;
  template: string;
  size?: ModalSizes;
  props?: T;
  disableClose?: boolean;
  listeners?: any;
  hideCloseButton?: boolean;
}

export interface IGenericModal {
  text?: string;
  warningText?: string;
  type?: ModalType;
  entityName?: string;
}

export interface ITenantsModal {
  tenantsMap: any[];
}

export interface IBulkDeployModal {
  numberOfChanges: number;
  environment: string;
  deploys: IBulkDeploy[];
  deployEnvironment: string;
}

export interface ICreateSchemaModal {
  prefillCreateName?: string;
  id?: string;
  duplicate?: boolean;
}
export interface IInputModal {
  text?: string;
  type?: string;
  value: string;
}

export interface ISelectorModal {
  options: ISelectOption[];
  current?: string;
}

export interface IEnvAndSourceModal {
  data: string;
}

export interface IHostnamesModal {
  domain: IDomain;
}

export interface IEnvClientDomainsModal {
  environmentClient: IEnvironmentClient;
  availableDomains: IDomain[];
}

export interface ISchemaDeployModal {
  versionOptions: ISelectOption[];
  schemaId: string;
  schemaType: SchemaType;
  deployments: ISchemaDeployment[];
}

export interface ISchemaDeployModalResponse {
  comment: string;
}

export interface IDryRunPane extends IEntitiesPane {
  defaultOption: DryRunOptionAlias;
  type: SchemaType;
  options: IDryRunOption[];
}

export interface IEntitiesPane {
  format: string;
  viewHandle: string;
}

export interface ICreate {
  type: CreateType;
  description: string;
  name: string;
  importDemoData?: boolean;
  id?: string;
  prefillCreateName?: string;
}

export interface ICreateSource {
  submitType: SubmitType;
  sourceGroup: ISourceGroupsResponse;
}

export enum CreateType {
  SCHEMA = 'schema',
  PARTIALSCHEMA = 'partialschema',
  SOURCE = 'source',
  TENANT = 'tenant',
  DATASOURCE = 'datasource',
  ENVIRONMENT = 'environment',
  ENVIRONMENTCLIENT = 'environmentclient',
  DOMAIN = 'domain',
  HOSTNAME = 'hostname',
  DUPLICATE_SCHEMA = 'duplicateschema',
  DUPLICATE_PARTIALSCHEMA = 'duplicatepartialschema',
  MANAGEMENTCLIENT = 'managementclient',
}

export enum SubmitType {
  UPDATE = 'Update',
  CREATE = 'Create',
}

export enum AddEntitiesModalStep {
  OPTIONS_SELECT = 'options-select',
  ADD_MANUALLY = 'add-manually',
  UPLOAD_FILE = 'upload-file',
}
