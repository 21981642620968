import { MutationTree, GetterTree, ActionTree } from 'vuex/types';

import { ModalSizes } from '../constants/ui/elementSizes';
import { IRootState, IModalState } from '~/src/models/store';

export const state = (): IModalState<unknown> => ({
  open: false,
  size: ModalSizes.LARGE,
  component: '',
  title: '',
  disableClose: false,
  props: {},
  listeners: {},
  hideCloseButton: false,
});

export const mutations: MutationTree<IModalState<unknown>> = {
  setOpen(state: IModalState<unknown>, newState: boolean): void {
    state.open = newState;
  },
  setSize(state: IModalState<unknown>, newSize: ModalSizes): void {
    state.size = newSize;
  },
  setComponent(state: IModalState<unknown>, newComponent: string): void {
    state.component = newComponent;
  },
  setProps(state: IModalState<unknown>, newProps: unknown): void {
    state.props = newProps;
  },
  setTitle(state: IModalState<unknown>, newTitle: string): void {
    state.title = newTitle;
  },
  setDisableClose(state: IModalState<unknown>, newDisableClose: boolean): void {
    state.disableClose = newDisableClose;
  },
  setListeners(state: IModalState<unknown>, newListeners: any): void {
    state.listeners = newListeners;
  },
  setHideCloseButton(
    state: IModalState<unknown>,
    newHideCloseButton: boolean,
  ): void {
    state.hideCloseButton = newHideCloseButton;
  },
};

export const getters: GetterTree<IModalState<unknown>, IRootState> = {
  isOpen(state: IModalState<unknown>): boolean {
    return state.open;
  },
  size(state: IModalState<unknown>): ModalSizes {
    return state.size;
  },
  component(state: IModalState<unknown>): string {
    return state.component;
  },
  props(state: IModalState<unknown>): unknown {
    return state.props;
  },
  disableClose(state: IModalState<unknown>): boolean {
    return state.disableClose;
  },
  title(state: IModalState<unknown>): string {
    return state.title;
  },
  listeners(state: IModalState<unknown>): any {
    return state.listeners;
  },
  hideCloseButton(state: IModalState<unknown>): boolean {
    return state.hideCloseButton;
  },
};

export const actions: ActionTree<IModalState<unknown>, IRootState> = {
  SET_MODAL_STATE({ commit }: { commit: any }, newState: boolean) {
    commit('setOpen', newState);
  },
  SET_MODAL_COMPONENT(
    { commit }: { commit: any },
    {
      template,
      props,
      title,
      size,
      open = true,
      listeners,
      disableClose = false,
      hideCloseButton = false,
    }: {
      template: string;
      props: unknown;
      title: string;
      size?: ModalSizes;
      open?: boolean;
      listeners?: any;
      disableClose?: boolean;
      hideCloseButton?: boolean;
    },
  ) {
    commit('setSize', size ?? ModalSizes.LARGE);
    commit('setComponent', template);
    commit('setProps', props);
    commit('setTitle', title);
    commit('setOpen', open);
    commit('setListeners', listeners);
    commit('setDisableClose', disableClose);
    commit('setHideCloseButton', hideCloseButton);
  },
  RESET_MODAL({ commit }: { commit: any }) {
    commit('setOpen', false);
    commit('setComponent', null);
    commit('setProps', {});
    commit('setTitle', '');
    commit('setDisableClose', false);
  },
};
