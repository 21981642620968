import { SchemaFormat } from '../../models/partial-schema';
import { IEditorFeedback, SchemaType } from '../../models/schema';
import { ITenant } from '../../models/tenant';
import { ISchemaMetadata, ISchemaVisitor } from './base';
import {
  JavascriptCollectionSchemaVisitor,
  JavascriptNormalSchemaVisitor,
  JavascriptPartialSchemaVisitor,
} from './javascript';
import {
  JsonPartialSchemaValidator,
  JsonSourceEntityTypesSchemaVisitor,
  JsonSourceGroupTriggerSchemaVisitor,
} from './json';
import { ObjectResult } from './result';

export const SchemaVisitor = (
  code: string,
  type: SchemaType | undefined,
  format: SchemaFormat | undefined,
  editorFeedback: IEditorFeedback | undefined,
  tenant: ITenant,
): ObjectResult<ISchemaMetadata> => {
  if (!type || !format) {
    return ObjectResult.ok<ISchemaMetadata>({
      sourceEntityTypes: [],
    });
  }

  if (editorFeedback?.invalid === true) {
    return ObjectResult.fail([{ description: 'schemaEditorHasErrors' }]); 
  }

  const visitors: ISchemaVisitor[] = [
    new JsonSourceEntityTypesSchemaVisitor(),
    new JavascriptNormalSchemaVisitor(),
    new JavascriptCollectionSchemaVisitor(),
    new JavascriptPartialSchemaVisitor(),
    new JsonSourceEntityTypesSchemaVisitor(),
    new JsonSourceGroupTriggerSchemaVisitor(),
    new JsonPartialSchemaValidator(),
  ];
  const matchingVisitor = visitors.find((f) =>
    f.canVisit(type, format, tenant),
  );
  if (matchingVisitor) {
    return matchingVisitor.visit(code);
  }

  return ObjectResult.ok<ISchemaMetadata>({
    sourceEntityTypes: [],
  });
};
