import { ObjectResult } from './result';
import { SchemaFormat } from '~/src/models/partial-schema';
import { SchemaType } from '~/src/models/schema';
import { ITenant } from '~/src/models/tenant';

export interface ISchemaVisitor {
  canVisit(type: SchemaType, format: SchemaFormat, tenant: ITenant): boolean;
  visit(code: string): ObjectResult<ISchemaMetadata>;
}

export abstract class BaseSchemaVisitor implements ISchemaVisitor {
  abstract get schemaTypes(): SchemaType[];
  abstract get schemaFormat(): SchemaFormat;
  abstract get usingSourceGroup(): boolean;

  canVisit(type: SchemaType, format: SchemaFormat, tenant: ITenant): boolean {
    return (
      this.schemaTypes.includes(type) &&
      format === this.schemaFormat &&
      tenant.isUsingSourceGroups === this.usingSourceGroup
    );
  }

  abstract visit(code: string): ObjectResult<ISchemaMetadata>;
}

export interface ISchemaMetadata {
  sourceEntityTypes: string[];
}
