import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IEnvironmentClientCreatePayload,
  IEnvironmentClientEditPayload,
} from '../models/environment-client';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/environment-clients');
  },
  create(environmentId: string, payload: IEnvironmentClientCreatePayload) {
    return $axios.post(
      `/api/v1/tenant/environments/${environmentId}/clients/`,
      payload,
    );
  },
  edit(
    environmentId: string,
    clientId: string,
    payload: IEnvironmentClientEditPayload,
  ) {
    return $axios.put(
      `/api/v1/tenant/environments/${environmentId}/clients/${clientId}`,
      payload,
    );
  },
  delete(environmentId: string, clientId: string) {
    return $axios.delete(
      `/api/v1/tenant/environments/${environmentId}/clients/${clientId}`,
    );
  },
});
