import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IEnterspeedContext } from '../models/es-app';
import {
  ISourceEntitiesApiGetPayload,
  ISourceEntitiesGetPayload,
  ISourceEntity,
} from '../models/sourceEntity';
import {
  IPaginationState,
  IRootState,
  ISourceEntityState,
} from '../models/store';

const initialState: ISourceEntityState = {
  entitiesLoading: false,
  entitiesList: [],
  entitiesPagination: null,
  sourceEntityTypes: [],
  total: 0,
};
export const state = (): ISourceEntityState => initialState;

export const mutations: MutationTree<ISourceEntityState> = {
  setEntitiesLoading(state: ISourceEntityState, loading: boolean): void {
    state.entitiesLoading = loading;
  },
  setEntitiesList(state: ISourceEntityState, entities: any[]): void {
    state.entitiesList = entities;
  },
  setEntitiesPagination(
    state: ISourceEntityState,
    newPagination: IPaginationState,
  ): void {
    state.entitiesPagination = newPagination;
  },
  setSourceEntityTypes(
    state: ISourceEntityState,
    sourceEntityTypes: string[],
  ): void {
    state.sourceEntityTypes = sourceEntityTypes;
  },
  deleteSourceEntities(
    state: ISourceEntityState,
    sourceEntities: ISourceEntity[],
  ): void {
    state.entitiesList = state.entitiesList.filter(
      (x) => !sourceEntities.find((deleted) => deleted.id === x.id),
    );
  },
  setTotal(state: ISourceEntityState, total?: number): void {
    state.total = !total ? state.total : total;
  },
};

export const getters: GetterTree<ISourceEntityState, IRootState> = {
  entitiesLoading(state: ISourceEntityState): boolean {
    return state.entitiesLoading;
  },
  entitiesPagination(state: ISourceEntityState): IPaginationState | null {
    return state.entitiesPagination;
  },
  entities(state: ISourceEntityState): any[] {
    return state.entitiesList;
  },
  sourceEntityTypes(state: ISourceEntityState): string[] {
    return state.sourceEntityTypes;
  },
  total(state: ISourceEntityState): number {
    return state.total;
  },
};

export const actions: ActionTree<ISourceEntityState, IRootState> = {
  async GET_SOURCE_ENTITIES(
    { commit, state }: { commit: any; state: ISourceEntityState },
    payload: ISourceEntitiesGetPayload,
  ) {
    commit('setEntitiesLoading', true);

    const { id, term, type, typeTerm } = payload;

    if (!id) {
      commit('setEntitiesList', []);
      commit('setEntitiesLoading', false);
      return;
    }

    const apiPayload: ISourceEntitiesApiGetPayload = {
      id,
      term,
      type,
      typeTerm,
    };

    const context = this.app.context as IEnterspeedContext;

    try {
      const [
        {
          data: sourceEntityPageResponse,
          headers: { 'x-continuation-token': continuationToken },
        },
        sourceEntityTypes,
      ] = await Promise.all([
        context.$sourceApi.getEntities(
          apiPayload,
          payload.next ? state.continuationToken : undefined,
          payload.cancelToken,
        ),
        context.$sourceApi.getSourceEntityTypes(id),
      ]);
      commit('setSourceEntityTypes', sourceEntityTypes);

      commit(
        'setEntitiesList',
        payload.next
          ? [...state.entitiesList, ...sourceEntityPageResponse.results]
          : sourceEntityPageResponse.results,
      );

      state.continuationToken = continuationToken;

      const { total } = sourceEntityPageResponse;

      commit('setTotal', total);

      const newPagination: IPaginationState = {
        total: state.total,
        pageInfo: {
          hasNextPage: state.continuationToken ? true : false,
          endCursor: state.continuationToken,
        },
      };

      commit('setEntitiesPagination', newPagination);
    } catch {}
    commit('setEntitiesLoading', false);
  },

  async GET_SOURCE_ENTITY_TYPES(
    { commit, state }: { commit: any; state: ISourceEntityState },
    id: string,
  ) {
    const context = this.app.context as IEnterspeedContext;
    const data = await context.$sourceApi.getSourceEntityTypes(id);
    commit('setSourceEntityTypes', data);

    return state.sourceEntityTypes;
  },

  DELETE_SOURCE_ENTITIES(
    { commit, dispatch }: { commit: any; dispatch: any },
    sourceEntities: ISourceEntity[],
  ) {
    commit('deleteSourceEntities', sourceEntities);
  },
};
