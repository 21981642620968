import { NuxtAxiosInstance } from '@nuxtjs/axios';
import qs from 'qs';
import {
  ITenantPayload,
  ITenantCreateInvitePayload,
  ITenantGetInvitesParams,
  ITenantGetUsersParams,
} from '../models/tenant';

export default ($axios: NuxtAxiosInstance) => ({
  getByIds(tenantGuids: string[]) {
    return $axios.get('/api/v1/tenants', {
      params: { ids: tenantGuids },
      paramsSerializer(params: any) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  create(payload: ITenantPayload) {
    return $axios.post('/api/v1/tenants', payload);
  },

  createInvite(payload: ITenantCreateInvitePayload) {
    return $axios.post('/api/v1/tenant/invites', payload);
  },

  getInvites(params: ITenantGetInvitesParams) {
    return $axios.get('/api/v1/tenant/invites', {
      params,
    });
  },

  cancelInvite(id: string) {
    return $axios.put(`/api/v1/tenant/invites/${id}/_cancel`);
  },

  resendInvite(id: string) {
    return $axios.put(`/api/v1/tenant/invites/${id}/_resend`);
  },

  addUser(email: string) {
    return $axios.put(`/api/v1/tenant/users/_add`, {
      email,
    });
  },

  removeUser(id: string) {
    return $axios.put(`/api/v1/tenant/users/${id}`, {
      tenantRoles: [],
    });
  },

  getUsers(params: ITenantGetUsersParams) {
    return $axios.get('/api/v1/tenant/users', {
      params,
    });
  },

  getDeliveryContext(routeId: string) {
    return $axios.post('/api/v1/tenant/routes/_delivery', { routeId });
  },

  deleteCurrentTenant() {
    return $axios.delete('/api/v1/tenants');
  },
});
