var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrapper",class:{
    'main-wrapper--pane-open': _vm.paneIsOpen,
  }},[_c('div',{staticClass:"default-layout"},[_c('the-header',{attrs:{"navigation":_vm.activeNavigation}}),_vm._v(" "),(_vm.availableTenants.length)?_c('div',{staticClass:"default-layout__wrapper",class:{
        'default-layout__wrapper--pane-open': _vm.paneIsOpen,
      }},[(_vm.$ld.ready && _vm.activeNavigation !== 'versionsNavigation')?_c('es-side-menu',{attrs:{"navigation":_vm.activeNavigation}}):_vm._e(),_vm._v(" "),_c('div',{ref:"content",staticClass:"default-layout__content",class:{
          'default-layout__content--pane-open': _vm.paneIsOpen,
        },style:({
          width: _vm.paneIsOpen ? _vm.contentWidth + 'px' : null,
          maxWidth: _vm.paneIsOpen ? _vm.contentWidth + 'px' : '100%',
        })},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isNotificationToastOpen)?_c('the-notification-toast',{attrs:{"is-open":_vm.isNotificationToastOpen}}):_vm._e()],1),_vm._v(" "),_c('nuxt')],1)],1):_c('div',[(
          _vm.$route.path === '/account/invitations' ||
          _vm.$route.path === '/account/invitations/'
        )?_c('div',{staticClass:"default-layout__no-tenant-view"},[_c('nuxt')],1):(!_vm.availableTenants.length)?_c('es-no-tenant',{attrs:{"type":"firstTenant"}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.modalIsOpen)?_c('the-modal'):_vm._e()],1),_vm._v(" "),_c('the-footer')],1),_vm._v(" "),(_vm.paneIsOpen)?_c('div',{staticClass:"main-wrapper__pane"},[_c('button',{ref:"handle",staticClass:"main-wrapper__handle"}),_vm._v(" "),_c('the-pane',{ref:"pane"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }