import { SourceLocation } from '@babel/types';
export interface IResult {
  valid: boolean;
  errors: ErrorDescriptor[];
}

export interface ErrorDescriptor {
  description: string;
  location?: Partial<SourceLocation> | null;
  column?: number;
}

export class Result implements IResult {
  valid: boolean;
  errors: ErrorDescriptor[];

  private constructor(valid: boolean, errors: ErrorDescriptor[] | undefined) {
    this.valid = valid;
    this.errors = errors ?? [];
  }

  static ok(): Result {
    return new Result(true, undefined);
  }

  static fail(errors: ErrorDescriptor[]): Result {
    return new Result(false, errors);
  }
}

export class ObjectResult<T> implements IResult {
  private _value: T | undefined;
  valid: boolean;
  errors: ErrorDescriptor[];

  private constructor(
    valid: boolean,
    errors: ErrorDescriptor[] | undefined,
    value?: T,
  ) {
    this.valid = valid;
    this.errors = errors ?? [];
    this._value = value;
  }

  static ok<T>(value: T): ObjectResult<T> {
    return new ObjectResult(true, undefined, value);
  }

  static fail<T>(errors: ErrorDescriptor[]): ObjectResult<T> {
    return new ObjectResult(false, errors);
  }

  public get value(): T {
    return this._value!;
  }
}
