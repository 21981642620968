import { LDUser } from 'launchdarkly-js-client-sdk';
import rg4js from 'raygun4js';
import {
  IAuthenticateResponse,
  IAuthenticateUserResponse,
} from '../models/auth';
import { IEnterspeedContext, IEnterspeedMiddleware } from '../models/es-app';
import { ITenant } from '../models/tenant';

const authMiddleware: IEnterspeedMiddleware = async (
  ctx: IEnterspeedContext,
) => {
  const { store } = ctx;

  try {
    if (store.getters['auth/accessToken']) return;
    const { token, links, redirectUrl, user }: IAuthenticateResponse =
      await store.dispatch('auth/AUTHENTICATE');
    await handleAuthResponse(
      ctx,
      token?.accessToken,
      links?.signInUrl,
      redirectUrl,
    );
    await fetchUserTenants(ctx, token?.accessToken, user);
  } catch (error) {
    return ctx.error({
      message: error.message,
    });
  }
};

const fetchUserTenants = async (
  ctx: IEnterspeedContext,
  accessToken?: string,
  user?: IAuthenticateUserResponse | undefined,
) => {
  if (!accessToken) {
    return;
  }

  const tenants = user?.tenants;

  const userTenantGuids = Object.keys(tenants ?? {}).map((tenantId) =>
    tenantId.replace('gid://Tenant/', ''),
  );
  if (userTenantGuids.length < 1) {
    return ctx.error({
      message: 'Tenants not found for user',
    });
  }

  try {
    const tenantsResult = await ctx.$tenantApi.getByIds(userTenantGuids);
    if (tenantsResult.status !== 200) {
      return ctx.error({
        message: 'Failed to fetch tenants',
      });
    }

    if (tenantsResult.data?.length < 1) {
      return ctx.error({
        message: 'Tenants not found for user',
      });
    }

    await ctx.store.dispatch('tenant/SET_AVAILABLE', tenantsResult.data);

    const preselectedTenant = <ITenant | null>(
      ctx.store.getters['tenant/current']
    );

    if (!preselectedTenant) {
      await ctx.store.dispatch('tenant/SET_CURRENT_TENANT_PROPERTIES', {
        name: tenantsResult.data[0].name,
        id: tenantsResult.data[0].id.idValue,
      });
    } else {
      const tenant = tenantsResult.data.find(
        (tenant: ITenant) => tenant.id.idValue === preselectedTenant.id.idValue,
      );

      ctx.store.dispatch('environments/GET_ENVIRONMENTS');

      if (user && tenant) {
        const ldUser: LDUser = {
          name: '',
          key: user.id,
          custom: {
            activeTenantId: tenant.id.idValue,
            activeTenantName: tenant.name,
            admin: !!user.roles?.includes('admin'),
          },
        };

        await ctx.$ld.identify(ldUser);

        rg4js('withCustomData', { tenant });
      }
    }
  } catch (error: any) {
    return ctx.error({
      message: error?.message,
    });
  }
};

const handleAuthResponse = (
  ctx: IEnterspeedContext,
  accessToken?: string,
  signInUrl?: string,
  redirectUrl?: string,
) => {
  return new Promise<void>((resolve, reject) => {
    if (accessToken) {
      (window as any).onNuxtReady(() => {
        ctx.app.$mixpanel('Opened Enterspeed');
        window.$nuxt.$router.push(redirectUrl || '/');
      });

      resolve();
    } else if (!accessToken && signInUrl) {
      window.location.replace(signInUrl);
      return;
    }
    reject(new Error('An error happened when authenticating your user'));
  });
};

export default authMiddleware;
