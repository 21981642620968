import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IApiResponse } from '../models/api';
import { IEnterspeedContext } from '../models/es-app';
import { ISourceCreatePayload } from '../models/source';
import {
  ISourceGroupsCreateWithSources,
  ISourceGroupsEditWithSources,
  ISourceGroupsResponse,
} from '../models/sourceGroups';
import { ISourceGroupState, IRootState } from '../models/store';
import { comparePossibleUndefinedProperty } from '../utils/helpers';

const initialState: ISourceGroupState = {
  sourceGroups: [],
  loading: false,
};
export const state = (): ISourceGroupState => initialState;

export const mutations: MutationTree<ISourceGroupState> = {
  setSourceGroups(
    state: ISourceGroupState,
    sourceGroups: ISourceGroupsResponse[],
  ): void {
    state.sourceGroups = sourceGroups;
  },
  setLoading(state: ISourceGroupState, loading: boolean): void {
    state.loading = loading;
  },
};

export const getters: GetterTree<ISourceGroupState, IRootState> = {
  sourceGroups(state: ISourceGroupState): ISourceGroupsResponse[] {
    return [...state.sourceGroups].sort((a, b) =>
      comparePossibleUndefinedProperty(a.name, b.name),
    );
  },
  loading(state: ISourceGroupState): boolean {
    return state.loading;
  },
};

export const actions: ActionTree<ISourceGroupState, IRootState> = {
  async GET_SOURCE_GROUPS({
    commit,
    state,
  }: { commit: any; state: ISourceGroupState; },
  force: boolean) : Promise<ISourceGroupsResponse[]> {
    if (force || !state.sourceGroups.length) {
      commit('setLoading', true);

      const context = this.app.context as IEnterspeedContext;

      const { data }: IApiResponse<ISourceGroupsResponse[]> =
        await context.$sourceGroupsApi.getAll();

      commit('setSourceGroups', data);
      commit('setLoading', false);
      return data;
    }
    else {
      return state.sourceGroups;
    }
  },

  async CREATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: ISourceGroupsCreateWithSources,
  ) {
    commit('setLoading', true);
    const context = this.app.context as IEnterspeedContext;

    const { data } = await context.$sourceGroupsApi.create(payload.group);

    payload.sources.forEach(async (source) => {
      const sourcePayload: ISourceCreatePayload = {
        name: source.name,
        environmentIds: source.environmentIds,
        sourceGroupId: data.idValue,
        type: payload.group.type,
      };
      await context.$sourceApi.create(sourcePayload);
    });

    setTimeout(() => {
      dispatch('GET_SOURCE_GROUPS', true);
    }, 300);
  },

  async EDIT(
    {
      state,
      commit,
      dispatch,
    }: { state: ISourceGroupState; commit: any; dispatch: any },
    payload: ISourceGroupsEditWithSources,
  ) {
    commit('setLoading', true);
    const context = this.app.context as IEnterspeedContext;

    await context.$sourceGroupsApi.edit(
      payload.id.sourceGroupGuid,
      payload.group,
    );

    const oldSourceGroup = state.sourceGroups.find(
      (x) => x.id.idValue === payload.id.idValue,
    );

    if (oldSourceGroup) {
      oldSourceGroup.sources.forEach(async (source) => {
        if (
          !payload.sources.some((x) => x.id === source.source.id.sourceGuid)
        ) {
          await context.$sourceApi.delete(source.source.id.sourceGuid);
        }
      });
    }

    payload.sources.forEach(async (source) => {
      if (!source.id) return;

      const sourcePayload: ISourceCreatePayload = {
        name: source.name,
        environmentIds: source.environmentIds,
        type: payload.group.type,
        sourceGroupId: payload.id.idValue,
      };

      if (source.id?.startsWith('new-')) {
        await context.$sourceApi.create(sourcePayload);
      } else {
        await context.$sourceApi.edit(source.id, sourcePayload);
      }
    });
    setTimeout(() => {
      dispatch('GET_SOURCE_GROUPS', true);
    }, 500);
  },

  async DELETE(
    { commit, dispatch }: { commit: any; dispatch: any },
    id: string,
  ) {
    commit('setLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$sourceGroupsApi.delete(id);

    dispatch('GET_SOURCE_GROUPS', true);
  },
};
