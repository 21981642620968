import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  ISchemaCreatePayload,
  IDeploySchemaPayload,
  ISchemaEditPayload,
  ISchemaVersionEditPayload,
  ISchemaDeliveryPayload,
  ISchemaDryRunPayload,
  ISchemaRelease,
} from '../models/schema';
import { DryRunInputFormat } from '../constants/ui/dryRun';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/mapping-schemas');
  },

  get(id: string, version?: number) {
    return $axios.get(`/api/v1/tenant/mapping-schemas/${id}`, {
      params: { version },
    });
  },

  test(payload: ISchemaDryRunPayload, format: DryRunInputFormat) {
    return $axios.post(`/api/v1/tenant/mapping-schemas/arbitrary-input-dry-run`, payload, {
      params: {
        format: format
      }
    });
  },

  create(payload: ISchemaCreatePayload) {
    return $axios.post('/api/v1/tenant/mapping-schemas', payload);
  },

  delivery(payload: ISchemaDeliveryPayload) {
    return $axios.post('/api/v1/tenant/mapping-schemas/delivery', payload);
  },

  edit(id: string, payload: ISchemaEditPayload) {
    return $axios.put(`/api/v1/tenant/mapping-schemas/${id}`, payload);
  },

  editVersion(id: string, version: number, payload: ISchemaVersionEditPayload) {
    return $axios.put(
      `/api/v1/tenant/mapping-schemas/${id}/version/${version}`,
      payload,
    );
  },
  deploy(payload: IDeploySchemaPayload) {
    return $axios.post(`/api/v1/tenant/mapping-schemas/deploy`, payload);
  },

  delete(id: string) {
    return $axios.delete('/api/v1/tenant/mapping-schemas/' + id);
  },

  createRelease(environmentId: string, schemas: ISchemaRelease[]) {
    return $axios.post('/api/v1/tenant/releases', {
      environmentId,
      schemas,
    });
  },
});
