import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import {
  INotificationToastConfig,
  INotificationDetails,
} from '../models/notification';
import NotificationType from '../constants/ui/notificationType';
import { IRootState, INotificationState } from '~/src/models/store';

export const state = (): INotificationState => ({
  notificationToastOpen: false,
  notificationToastType: undefined,
  notificationToastMessage: '',
  notificationToastDetails: undefined,
});

export const mutations: MutationTree<INotificationState> = {
  setNotificationToastOpen(state: INotificationState, newState: boolean): void {
    state.notificationToastOpen = newState;
  },
  toggleNotificationToastOpen(state: INotificationState): void {
    state.notificationToastOpen = !state.notificationToastOpen;
  },
  setNotificationToastMessage(
    state: INotificationState,
    newMessage: string,
  ): void {
    state.notificationToastMessage = newMessage;
  },
  setNotificationToastType(
    state: INotificationState,
    newType: NotificationType | undefined,
  ): void {
    state.notificationToastType = newType;
  },
  setNotificationToastDetails(
    state: INotificationState,
    newDetails: INotificationDetails | undefined,
  ): void {
    state.notificationToastDetails = newDetails;
  },
};

export const getters: GetterTree<INotificationState, IRootState> = {
  isNotificationToastOpen(state: INotificationState): boolean {
    return state.notificationToastOpen;
  },
  notificationToastMessage(state: INotificationState): string {
    return state.notificationToastMessage;
  },
  notificationToastType(
    state: INotificationState,
  ): NotificationType | undefined {
    return state.notificationToastType;
  },
  notificationToastDetails(
    state: INotificationState,
  ): INotificationDetails | undefined {
    return state.notificationToastDetails;
  },
};

export const actions: ActionTree<INotificationState, IRootState> = {
  SET_NOTIFICATION_TOAST_STATE({ commit }: { commit: any }, newState: boolean) {
    commit('setNotificationToastOpen', newState);
  },
  TOGGLE_NOTIFICATION_TOAST_STATE({ commit }: { commit: any }) {
    commit('toggleNotificationToastOpen');
  },
  SET_NOTIFICATION_MESSAGE({ commit }: { commit: any }, message: string) {
    commit('setNotificationToastMessage', message);
  },
  SET_NOTIFICATION_TYPE({ commit }: { commit: any }, type: NotificationType) {
    commit('setNotificationToastType', type);
  },
  SET_NOTIFICATION_ERRORS(
    { commit }: { commit: any },
    details: INotificationDetails,
  ) {
    commit('setNotificationToastDetails', details);
  },
  SET_NOTIFICATION_TOAST(
    { commit }: { commit: any },
    notificationToastConfig: INotificationToastConfig,
  ) {
    commit('setNotificationToastType', notificationToastConfig.type);
    commit('setNotificationToastMessage', notificationToastConfig.message);
    commit('setNotificationToastDetails', notificationToastConfig.details);
  },
  RESET_NOTIFICATION_TOAST({ commit }: { commit: any }) {
    commit('setNotificationToastOpen', false);
    commit('setNotificationToastMessage', '');
    commit('setNotificationToastType', null);
    commit('setNotificationToastDetails', null);
  },
};
