import { ObjectProperty } from '@babel/types';
import { ErrorDescriptor } from './result';
import { PropertiesValidator } from '~/src/utils/schema-visitors/properties-base.validator';

export class PropertiesFunctionValidator implements PropertiesValidator {
  validate(property: ObjectProperty): ErrorDescriptor[] {
    if (
      !['ArrowFunctionExpression', 'FunctionExpression'].some(
        (expression) => expression === property.value.type,
      )
    ) {
      return [
        {
          description: 'Properties must be a function',
          location: property.loc ? { ...property.loc } : undefined,
        },
      ];
    }
    return [];
  }
}
