import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IViewGetParams } from '../models/views';
import { CancelToken } from 'axios';

export default ($axios: NuxtAxiosInstance) => ({
  getAll(
    environmentId: string,
    params: IViewGetParams,
    cancelToken: CancelToken,
    continuationToken?: string,
  ) {
    const headers = continuationToken
      ? { 'x-continuation-token': continuationToken }
      : undefined;
    return $axios.get(
      `/api/v1/tenant/environments/${environmentId}/view-list`,
      {
        cancelToken,
        headers,
        params: { ...params },
      },
    );
  },

  get(id: string) {
    return $axios.get(`/api/v1/tenant/views`, {
      params: { id },
    });
  },
});
