import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import {
  IViewGetPayload,
  IViewListPaginationResponse,
  IViewListResponse,
  IViewResponse,
} from '../models/views';
import { IPaginationState, IRootState, IViewsState } from '~/src/models/store';

export const state = (): IViewsState => ({
  viewList: [],
  viewListLoading: false,
  views: {},
  viewLoading: false,
  pagination: null,
  total: 0,
});

export const mutations: MutationTree<IViewsState> = {
  setViewList(state: IViewsState, newViews: IViewListResponse[]): void {
    state.viewList = newViews;
  },
  setPagination(state: IViewsState, newPagination: IPaginationState): void {
    state.pagination = newPagination;
  },
  setViewListLoading(state: IViewsState, loading: boolean): void {
    state.viewListLoading = loading;
  },
  setViewLoading(state: IViewsState, loading: boolean): void {
    state.viewLoading = loading;
  },
  setView(state: IViewsState, view: IViewResponse) {
    state.views = {
      ...state.views,
      [view.id.idValue]: view,
    };
  },
  setTotalCount(state: IViewsState, total?: number) {
    state.total = total ? total : state.total;
  },
};

export const getters: GetterTree<IViewsState, IRootState> = {
  viewList(state: IViewsState): IViewListResponse[] {
    return state.viewList;
  },
  viewListLoading(state: IViewsState): boolean {
    return state.viewListLoading;
  },
  viewLoading(state: IViewsState): boolean {
    return state.viewLoading;
  },
  viewsPagination(state: IViewsState): IPaginationState | null {
    return state.pagination;
  },
  views(state: IViewsState): {
    [key: string]: IViewResponse;
  } {
    return state.views;
  },
  total(state: IViewsState) {
    return state.total;
  },
};

export const actions: ActionTree<IViewsState, IRootState> = {
  RESET_VIEWS({ commit }: { commit: any }) {
    commit('setViewList', []);
    commit('setPagination', null);
  },

  async GET_VIEWS(
    { commit, state }: { commit: any; state: IViewsState },
    payload: IViewGetPayload,
  ) {
    commit('setViewListLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const {
      data,
      headers: { 'x-continuation-token': continuationToken },
    }: IApiResponse<IViewListPaginationResponse> = await context.$viewsApi.getAll(
      payload.environmentId,
      payload.params,
      payload.cancelToken,
      payload.next ? state.continuationToken : undefined,
    );

    commit(
      'setViewList',
      payload.next ? [...state.viewList, ...data.results] : data.results,
    );
    commit('setTotalCount', data.total);

    state.continuationToken = continuationToken;

    const newPagination: IPaginationState = {
      total: state.total,
      pageInfo: {
        hasNextPage: state.continuationToken ? true : false,
        endCursor: state.continuationToken,
      },
    };

    commit('setPagination', newPagination);

    commit('setViewListLoading', false);
  },

  async GET_VIEW({ commit }: { commit: any; state: IViewsState }, id) {
    commit('setViewLoading', true);

    const context = this.app.context as IEnterspeedContext;

    try {
      const { data }: IApiResponse<IViewResponse> = await context.$viewsApi.get(
        id,
      );

      commit('setView', data);

      commit('setViewLoading', false);
      return data;
    } catch {
      commit('setViewLoading', false);
    }
  },
};
