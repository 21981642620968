var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"es-sidemenu"},[_vm._l((_vm.items),function(item){return _c('div',{key:item.path,staticClass:"es-sidemenu__item",class:{
      'es-sidemenu__item--active':
        item.path == '/' ? _vm.$route.path == '/' : _vm.isInPath(item.path),
    }},[(!item.hideNav)?_c('es-link',{staticClass:"es-sidemenu__item-link",attrs:{"to":item.path,"target":item.target,"data-cy":item.path}},[(item.iconAlias)?_c('svg-icon',{staticClass:"es-sidemenu__item-icon",attrs:{"name":item.iconAlias}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"es-sidemenu__item-link-text"},[_vm._v(_vm._s(item.label))])],1):_vm._e()],1)}),_vm._v(" "),_c('div',{staticClass:"es-sidemenu-mobile-popover",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('es-popover',{attrs:{"open":_vm.open,"align":_vm.alignPopover},on:{"clickedOutside":function($event){_vm.open = false}}},[_c('template',{slot:"trigger"},[_c('svg-icon',{staticClass:"es-sidemenu-mobile-popover__change-icon",class:_vm.open && 'es-sidemenu-mobile-popover__change-icon--active',attrs:{"name":_vm.swapIcon}})],1),_vm._v(" "),_c('div',{staticClass:"es-sidemenu-mobile-popover__items"},_vm._l((_vm.items),function(subitem){return _c('es-link',{key:'sub-' + subitem.path,staticClass:"es-sidemenu-mobile-popover__item-link",class:{
            'es-sidemenu-mobile-popover__item-link--active':
              subitem.path == '/'
                ? _vm.$route.path == '/'
                : _vm.isInPath(subitem.path),
          },attrs:{"to":subitem.path,"target":subitem.target,"data-cy":subitem.path},on:{"click":function($event){_vm.open = !_vm.open}}},[(subitem.iconAlias)?_c('svg-icon',{staticClass:"es-sidemenu-mobile-popover__item-icon",attrs:{"name":subitem.iconAlias}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"es-sidemenu-mobile-popover__item-link-text"},[_vm._v(_vm._s(subitem.label))])],1)}),1)],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }