import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import {
  IEnvironmentCreatePayload,
  IEnvironmentEditPayload,
  IEnvironmentId,
  IEnvironmentResponse,
} from '../models/environment';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import { IRootState, IEnvironmentsState } from '~/src/models/store';

export const state = (): IEnvironmentsState => ({
  environments: [],
  environmentsLoading: false,
});

export const mutations: MutationTree<IEnvironmentsState> = {
  setEnvironments(
    state: IEnvironmentsState,
    newEnvironments: IEnvironmentResponse[],
  ): void {
    state.environments = newEnvironments;
  },
  setEnvironmentsLoading(state: IEnvironmentsState, loading: boolean): void {
    state.environmentsLoading = loading;
  },
};

export const getters: GetterTree<IEnvironmentsState, IRootState> = {
  environments(state: IEnvironmentsState): IEnvironmentResponse[] {
    return state.environments;
  },
  environmentsLoading(state: IEnvironmentsState): boolean {
    return state.environmentsLoading;
  },
};

export const actions: ActionTree<IEnvironmentsState, IRootState> = {
  async CREATE(
    { commit, state }: { commit: any; state: IEnvironmentsState },
    payload: IEnvironmentCreatePayload,
  ) {
    commit('setEnvironmentsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    const { data }: IApiResponse<IEnvironmentId> =
      await context.$environmentApi.create(payload);

    const newEnvironment: IEnvironmentResponse = {
      id: data,
      name: payload.name,
    };

    const newEnvironments: IEnvironmentResponse[] = [
      ...state.environments,
      newEnvironment,
    ];
    commit('setEnvironments', newEnvironments);
    commit('setEnvironmentsLoading', false);
  },

  async EDIT(
    { commit, state }: { commit: any; state: IEnvironmentsState },
    payload: IEnvironmentEditPayload,
  ) {
    commit('setEnvironmentsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    const { data }: IApiResponse<IEnvironmentId> =
      await context.$environmentApi.edit(payload);

    const newEnvironment: IEnvironmentResponse = {
      id: data,
      name: payload.name,
    };

    const newEnvironments: IEnvironmentResponse[] = [
      ...state.environments.filter((x) => x.id.environmentGuid !== payload.id),
      newEnvironment,
    ];

    commit('setEnvironments', newEnvironments);
    commit('setEnvironmentsLoading', false);
  },

  async DELETE(
    { commit, state }: { commit: any; state: IEnvironmentsState },
    payload: string,
  ) {
    commit('setEnvironmentsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$environmentApi.delete(payload);

    const newEnvironments: IEnvironmentResponse[] = [
      ...state.environments.filter((x) => x.id.environmentGuid !== payload),
    ];
    commit('setEnvironments', newEnvironments);
    commit('setEnvironmentsLoading', false);
  },

  async GET_ENVIRONMENTS(
    { commit, state }: { commit: any; state: IEnvironmentsState },
    force: boolean,
  ) {
    if (force || !state.environments.length) {
      commit('setEnvironmentsLoading', true);

      const context = this.app.context as IEnterspeedContext;
      const { data }: IApiResponse<IEnvironmentResponse[]> =
        await context.$environmentApi.getAll();

      commit('setEnvironments', data);

      commit('setEnvironmentsLoading', false);
      return data;
    } else {
      return state.environments;
    }
  },
};
