import rg4js from 'raygun4js';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import StatusCode from '../constants/apiReponse';
import AuthRequestType from '../constants/auth';
import { IApiResponse } from '../models/api';
import {
  IAuthenticateRequestPayload,
  IAuthenticateResponse,
  IAuthLinks,
  IAuthToken,
  IAuthUser,
} from '../models/auth';
import { IEnterspeedContext } from '../models/es-app';
import { IRootState, IAuthState } from '../models/store';

export const state = (): IAuthState => ({
  isValid: false,
  tokens: {},
  links: {},
  user: {},
});

export const mutations: MutationTree<IAuthState> = {
  setIsValid(state: IAuthState, isValid: boolean): void {
    state.isValid = isValid;
  },
  setLinks(state: IAuthState, newLinks: IAuthLinks): void {
    state.links = newLinks;
  },
  setTokens(state: IAuthState, newTokens: IAuthToken): void {
    state.tokens = newTokens;
  },
  setUser(state: IAuthState, newUser: IAuthUser): void {
    state.user = newUser;
  },
};

export const getters: GetterTree<IAuthState, IRootState> = {
  isValid(state: IAuthState): boolean {
    return state.isValid;
  },
  isEnterspeedAdmin(state: IAuthState): boolean {
    return !!state.user?.roles?.includes('admin');
  },
  accessToken(state: IAuthState): string | undefined {
    return state.tokens?.accessToken;
  },
  refreshToken(state: IAuthState): string | undefined {
    return state.tokens?.refreshToken;
  },
  userLinks(state: IAuthState): IAuthLinks | undefined {
    return state.links;
  },
  signInUrl(state: IAuthState): string | undefined {
    return state.links?.signInUrl;
  },
  userName(state: IAuthState): string | undefined {
    return `${state.user?.firstName} ${state.user?.lastName}`;
  },
  userId(state: IAuthState): string | undefined {
    return `${state.user?.id}`;
  },
};

export const actions: ActionTree<IAuthState, IRootState> = {
  async AUTHENTICATE(
    {
      dispatch,
      commit,
      state,
    }: { dispatch: any; commit: any; state: IAuthState },
    refresh: boolean,
  ) {
    let response: IAuthenticateResponse = { isValid: false };
    try {
      const token = this.app.router?.currentRoute?.query?.code;
      const queryState = this.app.router?.currentRoute?.query?.state;
      const fullPath = this.app.router?.currentRoute?.fullPath;

      let payload: IAuthenticateRequestPayload = {
        type: AuthRequestType.AUTHENTICATE,
        token: token && typeof token === 'string' ? token : '',
        state: queryState && typeof queryState === 'string' ? queryState : '',
        frontendRedirectUrl:
          fullPath && typeof fullPath === 'string' ? fullPath : '',
      };

      if (refresh) {
        payload = {
          type: AuthRequestType.REFRESH,
          token: state.tokens?.refreshToken,
          state: '',
          frontendRedirectUrl: '',
        };
      }

      const context = this.app.context as IEnterspeedContext;

      const { status, data }: IApiResponse<IAuthenticateResponse> =
        await context.$authApi.authenticate(
          payload,
          this.app.$config.redirectUri,
        );
      if (status === StatusCode.OK && data.isValid) {
        const { isValid, links, token, user } = data;
        commit('setIsValid', isValid);
        commit('setLinks', links);
        commit('setTokens', token);
        commit('setUser', user);

        if (data.user && Object.keys(data.user.tenants).length) {
          const userTenantGuids = Object.keys(data.user?.tenants ?? {}).map(
            (tenantId) => tenantId.replace('gid://Tenant/', ''),
          );
          if (userTenantGuids.length > 0) {
            const tenantsResult = await context.$tenantApi.getByIds(
              userTenantGuids,
            );
            if (
              tenantsResult.status === 200 &&
              tenantsResult.data?.length > 0
            ) {
              dispatch('tenant/SET_AVAILABLE', tenantsResult.data, {
                root: true,
              });
            }
          }
        }

        response = data;

        rg4js('setUser', {
          identifier: `${user?.id}`,
          isAnonymous: false,
        });
      }
    } catch (error) {
      response = error.response?.data;
      const links = error.response?.data?.links;
      if (error.response?.status === StatusCode.FORBIDDEN && links) {
        commit('setLinks', links);
      }
    }

    return response;
  },

  async SIGN_OUT({ state }: { state: IAuthState }) {
    try {
      const context = this.app.context as IEnterspeedContext;
      const { status }: IApiResponse<void> = await context.$authApi.signOut();
      if (status === StatusCode.OK && state.links?.signOutUrl)
        window.location.replace(state.links?.signOutUrl);
      else throw new Error(this.app.i18n.t('signOutError').toString());
    } catch (error) {
      return error;
    }
  },
};
