import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IEnvironmentCreatePayload,
  IEnvironmentEditPayload,
} from '../models/environment';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/environments');
  },
  create(payload: IEnvironmentCreatePayload) {
    return $axios.post('/api/v1/tenant/environments', payload);
  },
  edit(payload: IEnvironmentEditPayload) {
    return $axios.put('/api/v1/tenant/environments/' + payload.id, {
      name: payload.name,
    });
  },
  delete(id: string) {
    return $axios.delete('/api/v1/tenant/environments/' + id);
  },
  getDeployments(id: string) {
    return $axios.get(`/api/v1/tenant/environments/${id}/deployments`);
  },
});
