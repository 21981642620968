import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IDeployedSchemaVersionsResponse } from '../models/deployed-schema-versions';
import { IEnterspeedContext } from '../models/es-app';
import { IDeployedSchemaVersionsState, IRootState } from '../models/store';

export const state = (): IDeployedSchemaVersionsState => ({
  loading: false,
  schemasFromEnv: null,
});

export const mutations: MutationTree<IDeployedSchemaVersionsState> = {
  setSchemasFromEnv(
    state: IDeployedSchemaVersionsState,
    schemasFromEnv: IDeployedSchemaVersionsResponse,
  ) {
    state.schemasFromEnv = schemasFromEnv;
  },
  setLoading(state: IDeployedSchemaVersionsState, loading: boolean): void {
    state.loading = loading;
  },
};

export const getters: GetterTree<IDeployedSchemaVersionsState, IRootState> = {
  schemasFromEnv(
    state: IDeployedSchemaVersionsState,
  ): IDeployedSchemaVersionsResponse | null {
    return state.schemasFromEnv;
  },
  loading(state: IDeployedSchemaVersionsState): boolean {
    return state.loading;
  },
};

export const actions: ActionTree<IDeployedSchemaVersionsState, IRootState> = {
  async GET_SCHEMAS_FROM_ENVIRONMENT(
    {
      commit,
    }: {
      commit: any;
    },
    environmentId: string,
  ) {
    commit('setLoading', true);
    const context = this.app.context as IEnterspeedContext;
    const { data } = await context.$environmentApi.getDeployments(
      environmentId.replace('gid://Environment/', ''),
    );

    commit('setSchemasFromEnv', data);
    commit('setLoading', false);

    return data.current.schemas;
  },
};
