import { LDUser } from 'launchdarkly-js-client-sdk';
import rg4js from 'raygun4js';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IApiResponse, IPaginated, IPagination } from '../models/api';
import { IEnterspeedContext } from '../models/es-app';
import { IPaginationState, IRootState, ITenantState } from '../models/store';
import {
  ITenant,
  ITenantAdminAllTenantGetPayload,
  ITenantGetAdminAllTenantsParams,
  ITenantCreate,
  ITenantId,
  ITentantInvitesGetPayload,
  ITenantInvite,
  ITenantCreateInvitePayload,
  ITenantUser,
  ITentantUsersGetPayload,
  ITenantProperties,
  PartialSchemaFirstClassCitizenOptions,
  JavascriptSchemaFormatConfig,
} from '../models/tenant';

const initialState: ITenantState = {
  currentTenantId: window.localStorage.getItem('enterspeed-tenant-id'),
  currentViewingTenantId: window.localStorage.getItem(
    'enterspeed-viewing-tenant-id',
  ),
  currentViewingTenantName: window.localStorage.getItem(
    'enterspeed-viewing-tenant-name',
  ),
  currentTenantIsUsingSourceGroups: false,
  currentTenantIsUsingSchemasBulkDeployment: false,
  available: [],
  tenantLoading: false,
  adminAllTenantsLoading: false,
  adminAllTenantsPagination: null,
  adminAllTenantsList: [],
  invitesLoading: false,
  invitesPagination: null,
  inviteList: [],
  usersLoading: false,
  usersPagination: null,
  userList: [],
  partialSchemaFirstClassCitizenOption: 'disabled',
  javascriptSchemaFormatConfig: {
    enabled: false,
  },
};
export const state = (): ITenantState => initialState;

export const mutations: MutationTree<ITenantState> = {
  setCurrentTenantId(state: ITenantState, id: string): void {
    window.localStorage.setItem('enterspeed-tenant-id', id);
    state.currentTenantId = id;
  },
  setCurrentViewingTenant(
    state: ITenantState,
    {
      id,
      name,
      isUsingSchemasBulkDeployment,
      isUsingSourceGroups,
      partialSchemaFirstClassCitizenOption,
      javascriptSchemaFormatConfig,
    }: {
      id: string;
      name: string;
      isUsingSchemasBulkDeployment: string;
      isUsingSourceGroups: string;
      partialSchemaFirstClassCitizenOption: PartialSchemaFirstClassCitizenOptions;
      javascriptSchemaFormatConfig: JavascriptSchemaFormatConfig;
    },
  ): void {
    if (id) {
      window.localStorage.setItem('enterspeed-viewing-tenant-id', id);
    } else {
      window.localStorage.removeItem('enterspeed-viewing-tenant-id');
    }
    if (name) {
      window.localStorage.setItem('enterspeed-viewing-tenant-name', name);
    } else {
      window.localStorage.removeItem('enterspeed-viewing-tenant-name');
    }
    if (isUsingSchemasBulkDeployment) {
      window.localStorage.setItem(
        'enterspeed-viewing-tenant-using-bulk-deploy',
        'true',
      );
    } else {
      window.localStorage.removeItem(
        'enterspeed-viewing-tenant-using-bulk-deploy',
      );
    }
    if (isUsingSourceGroups) {
      window.localStorage.setItem(
        'enterspeed-viewing-tenant-using-source-groups',
        'true',
      );
    } else {
      window.localStorage.removeItem(
        'enterspeed-viewing-tenant-using-source-groups',
      );
    }

    state.currentViewingTenantId = id;
    state.currentViewingTenantName = name;
    state.partialSchemaFirstClassCitizenOption =
      partialSchemaFirstClassCitizenOption;
    state.javascriptSchemaFormatConfig = javascriptSchemaFormatConfig;
  },
  setTenantLoading(state: ITenantState, loading: boolean): void {
    state.tenantLoading = loading;
  },
  setAvailable(state: ITenantState, tenants: ITenant[]): void {
    state.available = tenants;
  },
  setAdminAllTenantsLoading(state: ITenantState, loading: boolean): void {
    state.adminAllTenantsLoading = loading;
  },
  setAdminAllTenantsList(state: ITenantState, tenants: ITenant[]): void {
    state.adminAllTenantsList = tenants;
  },
  setAdminAllTenantsPagination(
    state: ITenantState,
    newPagination: IPaginationState,
  ): void {
    state.adminAllTenantsPagination = newPagination;
  },
  setInvitesLoading(state: ITenantState, loading: boolean): void {
    state.invitesLoading = loading;
  },
  setInviteList(state: ITenantState, invites: ITenantInvite[]): void {
    state.inviteList = invites;
  },
  setInvitesPagination(
    state: ITenantState,
    newPagination: IPaginationState,
  ): void {
    state.invitesPagination = newPagination;
  },
  setUsersLoading(state: ITenantState, loading: boolean): void {
    state.usersLoading = loading;
  },
  setUserList(state: ITenantState, users: ITenantUser[]): void {
    state.userList = users;
  },
  setUsersPagination(
    state: ITenantState,
    newPagination: IPaginationState,
  ): void {
    state.usersPagination = newPagination;
  },
  setCurrentTenantIsUsingSourceGroups(
    state: ITenantState,
    isUsingSourceGroups: boolean,
  ): void {
    state.currentTenantIsUsingSourceGroups = isUsingSourceGroups;
  },
  setCurrentTenantIsUsingSchemasBulkDeployment(
    state: ITenantState,
    isUsingSchemasBulkDeployment: boolean,
  ): void {
    state.currentTenantIsUsingSchemasBulkDeployment =
      isUsingSchemasBulkDeployment;
  },
  setPartialSchemaFirstClassCitizenOption(
    state: ITenantState,
    partialSchemaFirstClassCitizenOption: PartialSchemaFirstClassCitizenOptions,
  ): void {
    state.partialSchemaFirstClassCitizenOption =
      partialSchemaFirstClassCitizenOption;
  },
  setJavascriptSchemaFormatConfig(
    state: ITenantState,
    javascriptSchemaFormatConfig: JavascriptSchemaFormatConfig,
  ): void {
    state.javascriptSchemaFormatConfig = javascriptSchemaFormatConfig;
  },
};

export const getters: GetterTree<ITenantState, IRootState> = {
  current(state: ITenantState): any | null {
    if (state.currentViewingTenantId) {
      return {
        name: state.currentViewingTenantName,
        id: {
          idValue: state.currentViewingTenantId,
        },
        isUsingSourceGroups:
          window.localStorage.getItem(
            'enterspeed-viewing-tenant-using-source-groups',
          ) === 'true' && true,
        isUsingSchemasBulkDeployment:
          window.localStorage.getItem(
            'enterspeed-viewing-tenant-using-bulk-deploy',
          ) === 'true' && true,
        partialSchemaFirstClassCitizenOption:
          state.partialSchemaFirstClassCitizenOption,
        javascriptSchemaFormatConfig: state.javascriptSchemaFormatConfig,
      };
    } else {
      return (
        state.available.find(
          (tenant: ITenant) => tenant.id.idValue === state.currentTenantId,
        ) ?? null
      );
    }
  },
  currentId(state: ITenantState): string | null {
    if (state.currentViewingTenantId) {
      return state.currentViewingTenantId;
    } else {
      return state.currentTenantId;
    }
  },
  available(state: ITenantState): ITenant[] {
    return state.available;
  },
  adminIsViewing(state: ITenantState): boolean {
    return !!state.currentViewingTenantId;
  },
  adminAllTenantsLoading(state: ITenantState): boolean {
    return state.adminAllTenantsLoading;
  },
  adminAllTenantsPagination(state: ITenantState): IPaginationState | null {
    return state.adminAllTenantsPagination;
  },
  adminAllTenantsList(state: ITenantState): ITenant[] {
    return state.adminAllTenantsList;
  },
  invitesLoading(state: ITenantState): boolean {
    return state.invitesLoading;
  },
  invitesPagination(state: ITenantState): IPaginationState | null {
    return state.invitesPagination;
  },
  invites(state: ITenantState): ITenantInvite[] {
    return state.inviteList;
  },
  usersLoading(state: ITenantState): boolean {
    return state.usersLoading;
  },
  usersPagination(state: ITenantState): IPaginationState | null {
    return state.usersPagination;
  },
  users(state: ITenantState): ITenantUser[] {
    return state.userList;
  },
};

export const actions: ActionTree<ITenantState, IRootState> = {
  SET_CURRENT_TENANT_PROPERTIES(
    {
      dispatch,
      commit,
      getters,
      rootState,
    }: { dispatch: any; commit: any; getters: any; rootState: any },
    payload: ITenantProperties,
  ) {
    if (getters.available.find((x: ITenant) => x.id.idValue === payload.id)) {
      commit('setCurrentViewingTenant', {
        name: null,
        id: null,
        isUsingSourceGroups: null,
        isUsingSchemasBulkDeployment: null,
        partialSchemaFirstClassCitizenOption: null,
        javascriptSchemaFormatConfig: null,
      });
      commit('setCurrentTenantId', payload.id);
      commit(
        'setCurrentTenantIsUsingSourceGroups',
        payload.isUsingSourceGroups,
      );
      commit(
        'setCurrentTenantIsUsingSchemasBulkDeployment',
        payload.isUsingSchemasBulkDeployment,
      );
      commit(
        'setPartialSchemaFirstClassCitizenOption',
        payload.partialSchemaFirstClassCitizenOption,
      );
      commit(
        'setJavascriptSchemaFormatConfig',
        payload.javascriptSchemaFormatConfig,
      );
    } else {
      commit('setCurrentViewingTenant', {
        name: payload.name,
        id: payload.id,
        isUsingSourceGroups: payload.isUsingSourceGroups,
        isUsingSchemasBulkDeployment: payload.isUsingSchemasBulkDeployment,
        partialSchemaFirstClassCitizenOption:
          payload.partialSchemaFirstClassCitizenOption,
        javascriptSchemaFormatConfig: payload.javascriptSchemaFormatConfig,
      });
      commit(
        'setCurrentTenantIsUsingSourceGroups',
        payload.isUsingSourceGroups,
      );
      commit(
        'setCurrentTenantIsUsingSchemasBulkDeployment',
        payload.isUsingSchemasBulkDeployment,
      );
      commit(
        'setPartialSchemaFirstClassCitizenOption',
        payload.partialSchemaFirstClassCitizenOption,
      );
      commit(
        'setJavascriptSchemaFormatConfig',
        payload.javascriptSchemaFormatConfig,
      );
    }

    const tenant: ITenant = getters.current;

    rg4js('withCustomData', { tenant });

    const user = rootState.auth.user;

    const ldUser: LDUser = {
      name: '',
      key: user.id,
      custom: {
        activeTenantId: tenant.id.idValue,
        activeTenantName: tenant.name,
        admin: !!user.roles?.includes('admin'),
      },
    };

    (this as any).$ld.identify(ldUser);

    if (!payload.avoidRefresh) {
      dispatch('REFRESH_TENANT_DATA');
    }
  },

  REFRESH_TENANT_DATA({ dispatch }: { dispatch: any }) {
    dispatch('environments/GET_ENVIRONMENTS', true, { root: true });
    dispatch('environment-clients/GET_ENVIRONMENT_CLIENTS', true, {
      root: true,
    });
    dispatch('sources/GET_SOURCES', true, { root: true });
    dispatch('source-groups/GET_SOURCE_GROUPS', true, { root: true });
    dispatch('partial-schema/GET_PARTIAL_SCHEMAS', true, { root: true });
    dispatch('schema/GET_SCHEMAS', true, { root: true });
    dispatch('domains/GET_DOMAINS', true, { root: true });
    dispatch('GET_USERS');
    dispatch('management-clients/GET_MANAGEMENT_CLIENTS', true, { root: true });
    dispatch('schema-folders/RESET_SCHEMA_FOLDERS_STATUS', true, {
      root: true,
    });
  },

  SET_AVAILABLE({ commit }: { commit: any }, tenants: ITenant[]) {
    commit('setAvailable', tenants);
  },

  async CREATE(
    { commit, dispatch, getters }: { commit: any; dispatch: any; getters: any },
    payload: ITenantCreate,
  ) {
    commit('setTenantLoading', true);

    const context = this.app.context as IEnterspeedContext;
    const { data }: IApiResponse<ITenantId> = await context.$tenantApi.create(
      payload.tenant,
    );

    const newTenant = await context.$tenantApi.getByIds([
      data.idValue.replace('gid://Tenant/', ''),
    ]);

    const newAvailableTenants: ITenant[] = [...getters.available];

    newAvailableTenants.push(newTenant.data[0]);

    commit('setAvailable', newAvailableTenants);

    dispatch('SET_CURRENT_TENANT_PROPERTIES', {
      id: data.idValue,
      avoidRefresh: true,
    });

    if (payload.datasets !== null && payload.datasets !== undefined) {
      await context.$datasetsApi.import(payload.datasets);
    }

    dispatch('REFRESH_TENANT_DATA');

    commit('setTenantLoading', false);
  },

  async GET_ADMINTENANTS(
    { commit, state }: { commit: any; state: ITenantState },
    payload: ITenantAdminAllTenantGetPayload,
  ) {
    commit('setAdminAllTenantsLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const pagination: ITenantGetAdminAllTenantsParams = {
      first: 100,
      after: payload.next
        ? state.adminAllTenantsPagination?.pageInfo.endCursor
        : undefined,
      term: payload.term,
    };

    const { data }: IApiResponse<IPaginated<ITenant>> =
      await context.$adminApi.getAsAdmin(pagination);

    commit(
      'setAdminAllTenantsList',
      payload.next
        ? [...state.adminAllTenantsList, ...data.results]
        : data.results,
    );

    const { total, pageInfo } = data;
    const newPagination: IPaginationState = {
      total,
      pageInfo,
    };

    commit('setAdminAllTenantsPagination', newPagination);

    commit('setAdminAllTenantsLoading', false);
  },

  async CREATE_INVITE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: ITenantCreateInvitePayload,
  ) {
    commit('setInvitesLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$tenantApi.createInvite(payload);

      dispatch('GET_INVITES');
    } catch {
      commit('setInvitesLoading', false);
    }
  },

  async RESEND_INVITE(
    { commit, dispatch }: { commit: any; dispatch: any },
    id: string,
  ) {
    commit('setInvitesLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$tenantApi.resendInvite(id);

      dispatch('GET_INVITES');
    } catch {
      commit('setInvitesLoading', false);
    }
  },

  async CANCEL_INVITE(
    { commit, dispatch }: { commit: any; dispatch: any },
    id: string,
  ) {
    commit('setInvitesLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$tenantApi.cancelInvite(id);

      dispatch('GET_INVITES');
    } catch {
      commit('setInvitesLoading', false);
    }
  },

  async GET_INVITES(
    { commit, state }: { commit: any; state: ITenantState },
    payload?: ITentantInvitesGetPayload,
  ) {
    commit('setInvitesLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const pagination: IPagination = {
      first: 10,
      after: payload?.next
        ? state.invitesPagination?.pageInfo.endCursor
        : undefined,
    };

    const { data }: IApiResponse<IPaginated<ITenantInvite>> =
      await context.$tenantApi.getInvites(pagination);

    commit(
      'setInviteList',
      payload?.next ? [...state.inviteList, ...data.results] : data.results,
    );

    const { total, pageInfo } = data;
    const newPagination: IPaginationState = {
      total,
      pageInfo,
    };

    commit('setInvitesPagination', newPagination);

    commit('setInvitesLoading', false);
  },

  async ADD_USER(
    { commit, dispatch }: { commit: any; dispatch: any },
    email: string,
  ) {
    commit('setUsersLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$tenantApi.addUser(email);

      dispatch('GET_USERS');
    } catch {
      commit('setUsersLoading', false);
    }
  },

  async REMOVE_USER(
    { commit, dispatch }: { commit: any; dispatch: any },
    id: string,
  ) {
    commit('setUsersLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$tenantApi.removeUser(id);

      dispatch('GET_USERS');
    } catch {
      commit('setUsersLoading', false);
    }
  },

  async GET_USERS(
    { commit, state }: { commit: any; state: ITenantState },
    payload?: ITentantUsersGetPayload,
  ) {
    commit('setUsersLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const pagination: IPagination = {
      first: 5,
      after: payload?.next
        ? state.usersPagination?.pageInfo.endCursor
        : undefined,
    };

    const { data }: IApiResponse<IPaginated<ITenantUser>> =
      await context.$tenantApi.getUsers(pagination);

    commit(
      'setUserList',
      payload?.next ? [...state.userList, ...data.results] : data.results,
    );

    const { total, pageInfo } = data;
    const newPagination: IPaginationState = {
      total,
      pageInfo,
    };

    commit('setUsersPagination', newPagination);

    commit('setUsersLoading', false);
  },

  async DELETE_CURRENT_TENANT() {
    const context = this.app.context as IEnterspeedContext;
    await context.$tenantApi.deleteCurrentTenant();

    window.location.replace('/');
  },
};
