import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import {
  IBulkDeploy,
  IBulkDeployPostPayload,
  IBulkDeployResponse,
  IBulkDeployStep,
} from '../models/bulk-deploy';
import { IEnterspeedContext } from '../models/es-app';
import { ISchemaRelease } from '../models/schema';
import { IBulkDeployState, IFailedRelease, IRootState } from '../models/store';

export const state = (): IBulkDeployState => ({
  loading: false,
  data: null,
  schemasFromEnv: null,
  schemasFromEnvLoading: false,
  deploys: [],
  step: null,
  failedReleases: [],
});

export const mutations: MutationTree<IBulkDeployState> = {
  setBulkLoading(state: IBulkDeployState, loading: boolean): void {
    state.loading = loading;
  },
  setBulkData(state: IBulkDeployState, data: IBulkDeployResponse) {
    state.data = data;
  },
  setSchemasFromEnv(
    state: IBulkDeployState,
    schemasFromEnv: IBulkDeployResponse,
  ) {
    state.schemasFromEnv = schemasFromEnv;
  },
  setGetSchemasFromEnvLoading(state: IBulkDeployState, loading: boolean): void {
    state.schemasFromEnvLoading = loading;
  },
  setDeploys(state: IBulkDeployState, deploys: IBulkDeploy[]) {
    state.deploys = deploys;
  },
  setStep(state: IBulkDeployState, step: IBulkDeployStep) {
    state.step = step;
  },
  setFailedReleases(state: IBulkDeployState, failedReleases: IFailedRelease[]) {
    state.failedReleases = failedReleases;
  },
};

export const getters: GetterTree<IBulkDeployState, IRootState> = {
  data(state: IBulkDeployState): IBulkDeployResponse | null {
    return state.data;
  },
  schemasFromEnv(state: IBulkDeployState): IBulkDeployResponse | null {
    return state.schemasFromEnv;
  },
  loading(state: IBulkDeployState): boolean {
    return state.loading;
  },
  schemasFromEnvLoading(state: IBulkDeployState): boolean {
    return state.schemasFromEnvLoading;
  },
  deploys(state: IBulkDeployState): IBulkDeploy[] {
    return state.deploys;
  },
  step(state: IBulkDeployState): IBulkDeployStep | null {
    return state.step;
  },
  failedReleases(state: IBulkDeployState): IFailedRelease[] {
    return state.failedReleases;
  },
};

export const actions: ActionTree<IBulkDeployState, IRootState> = {
  RESET_STEP({ commit }: { commit: any }) {
    commit('setStep', null);
  },

  async GET_BULK_DEPLOY_SCHEMAS(
    {
      commit,
    }: {
      commit: any;
    },
    environmentId: string,
  ) {
    commit('setBulkLoading', true);

    const context = this.app.context as IEnterspeedContext;
    const { data } = await context.$environmentApi.getDeployments(
      environmentId.replace('gid://Environment/', ''),
    );

    commit('setBulkData', data);
    commit('setBulkLoading', false);
  },

  SET_FAILED_RELEASES(
    { commit }: { commit: any },
    failedReleases: IFailedRelease[],
  ) {
    commit('setFailedReleases', failedReleases);
  },

  async GET_SCHEMAS_FROM_ENVIRONMENT(
    {
      commit,
    }: {
      commit: any;
    },
    environmentId: string,
  ) {
    commit('setGetSchemasFromEnvLoading', true);
    const context = this.app.context as IEnterspeedContext;
    const { data } = await context.$environmentApi.getDeployments(
      environmentId.replace('gid://Environment/', ''),
    );

    commit('setSchemasFromEnv', data);
    commit('setGetSchemasFromEnvLoading', false);

    return data.current.schemas;
  },

  POST_BULK_DEPLOY_SCHEMAS(
    {
      commit,
    }: {
      commit: any;
    },
    payload: IBulkDeployPostPayload,
  ) {
    commit('setBulkLoading', true);
    commit('setDeploys', payload.deploys);

    const { environmentId } = payload;

    const context = this.app.context as IEnterspeedContext;

    const deploys: ISchemaRelease[] = [...payload.deploys].map(
      (deploy: IBulkDeploy) =>
        ({
          schemaId: deploy.id,
          version: !deploy.deployVersion?.value
            ? null
            : parseInt(deploy.deployVersion?.value.toString()),
        } as ISchemaRelease),
    );

    const deploySchemas = async () => {
      commit('setStep', IBulkDeployStep.DEPLOYING);

      await context.$schemaApi.createRelease(environmentId, deploys);
    };

    deploySchemas()
      .then(() => {
        commit('setStep', IBulkDeployStep.COMPLETED);
      })
      .catch(() => {
        commit('setStep', IBulkDeployStep.FAILED);
      });

    commit('setBulkLoading', false);
  },
};
