import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IRootState, IPaneState } from '~/src/models/store';

export const state = (): IPaneState<unknown> => ({
  open: false,
  width: window.innerWidth / 2.5,
  component: '',
  back: false,
  title: '',
  props: {},
  listeners: {},
});

export const mutations: MutationTree<IPaneState<unknown>> = {
  setOpen(state: IPaneState<unknown>, isOpen: boolean): void {
    state.open = isOpen;
  },
  setComponent(state: IPaneState<unknown>, newComponent: string): void {
    state.component = newComponent;
  },
  setProps(state: IPaneState<unknown>, newProps: unknown): void {
    state.props = newProps;
  },
  setTitle(state: IPaneState<unknown>, newTitle: string): void {
    state.title = newTitle;
  },
  setBack(state: IPaneState<unknown>, back: boolean): void {
    state.back = back;
  },
  setListeners(state: IPaneState<unknown>, newListeners: any): void {
    state.listeners = newListeners;
  },
  setWidth(state: IPaneState<unknown>, newWidth: number): void {
    state.width = newWidth;
  },
};

export const getters: GetterTree<IPaneState<unknown>, IRootState> = {
  isOpen(state: IPaneState<unknown>): boolean {
    return state.open;
  },
  component(state: IPaneState<unknown>): string {
    return state.component;
  },
  props(state: IPaneState<unknown>): unknown {
    return state.props;
  },
  title(state: IPaneState<unknown>): string {
    return state.title;
  },
  listeners(state: IPaneState<unknown>): any {
    return state.listeners;
  },
  width(state: IPaneState<unknown>): number {
    return state.width;
  },
  back(state: IPaneState<unknown>): boolean {
    return state.back;
  },
};

export const actions: ActionTree<IPaneState<unknown>, IRootState> = {
  SET_PANE_COMPONENT(
    { commit }: { commit: any },
    {
      template,
      back,
      props,
      title,
      open = true,
      listeners,
    }: {
      template: string;
      back: boolean;
      props: unknown;
      title: string;
      open: boolean;
      listeners?: any;
    },
  ) {
    commit('setComponent', template);
    commit('setProps', props);
    commit('setTitle', title);
    commit('setBack', back);
    commit('setOpen', open);
    commit('setListeners', listeners);
  },
  RESET_PANE({ commit }: { commit: any }) {
    commit('setOpen', false);
    commit('setComponent', null);
    commit('setProps', {});
    commit('setTitle', '');
    commit('setBack', false);
  },
  SET_PANE_WIDTH({ commit }: { commit: any }, newWidth: number) {
    commit('setWidth', newWidth);
  },
};
