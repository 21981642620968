import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IRootState, INotFoundState } from '~/src/models/store';

export const state = (): INotFoundState => ({
  isNotFound: false,
  entity: '',
});

export const mutations: MutationTree<INotFoundState> = {
  setIsNotFound(state: INotFoundState, newData: boolean): void {
    state.isNotFound = newData;
  },
  setEntity(state: INotFoundState, newData: string): void {
    state.entity = newData;
  },
};

export const getters: GetterTree<INotFoundState, IRootState> = {
  entity(state: INotFoundState): string {
    return state.entity;
  },
  isNotFound(state: INotFoundState): boolean {
    return state.isNotFound;
  },
};

export const actions: ActionTree<INotFoundState, IRootState> = {
  SET_NOT_FOUND({ commit }: { commit: any }, entity: string) {
    commit('setIsNotFound', true);
    commit('setEntity', entity);
  },

  RESET_NOT_FOUND({ commit }: { commit: any }) {
    commit('setIsNotFound', false);
    commit('setEntity', '');
  },
};
