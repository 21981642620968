import { Context, Inject } from '@nuxt/types/app';
import authApi from '../api/auth.api';
import tenantApi from '../api/tenant.api';
import sourceApi from '../api/source.api';
import environmentApi from '../api/environment.api';
import domainApi from '../api/domain.api';
import logsApi from '../api/logs.api';
import datasetsApi from '../api/datasets.api';
import environmentClientApi from '../api/environment-client.api';
import schemaApi from '../api/schema.api';
import partialSchemaApi from '../api/partial-schema.api';
import settingsApi from '../api/settings.api';
import viewsApi from '../api/views.api';
import invitesApi from '../api/invites.api';
import adminApi from '../api/admin.api';
import sourceGroupsApi from '../api/source-groups.api';
import managementClientApi from '../api/management-client.api';
import routesApi from '../api/routes.api';

export default (ctx: Context, inject: Inject) => {
  inject('authApi', authApi(ctx.$axios));
  inject('tenantApi', tenantApi(ctx.$axios));
  inject('sourceApi', sourceApi(ctx.$axios));
  inject('environmentApi', environmentApi(ctx.$axios));
  inject('environmentClientApi', environmentClientApi(ctx.$axios));
  inject('domainApi', domainApi(ctx.$axios));
  inject('logsApi', logsApi(ctx.$axios));
  inject('datasetsApi', datasetsApi(ctx.$axios));
  inject('schemaApi', schemaApi(ctx.$axios));
  inject('partialSchemaApi', partialSchemaApi(ctx.$axios));
  inject('settingsApi', settingsApi(ctx.$axios));
  inject('viewsApi', viewsApi(ctx.$axios));
  inject('invitesApi', invitesApi(ctx.$axios));
  inject('adminApi', adminApi(ctx.$axios));
  inject('sourceGroupsApi', sourceGroupsApi(ctx.$axios));
  inject('managementClientApi', managementClientApi(ctx.$axios));
  inject('routesApi', routesApi(ctx.$axios));
};
