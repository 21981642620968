import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6c677200&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=6c677200&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c677200",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/app/src/components/global/TheHeader.vue').default,EsSideMenu: require('/app/src/components/global/EsSideMenu.vue').default,TheNotificationToast: require('/app/src/components/global/TheNotificationToast.vue').default,EsNoTenant: require('/app/src/components/content/EsNoTenant.vue').default,TheModal: require('/app/src/components/global/TheModal.vue').default,TheFooter: require('/app/src/components/global/TheFooter.vue').default,ThePane: require('/app/src/components/global/ThePane.vue').default})
