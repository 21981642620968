import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import {
  IPartialSchemaCreatePayload,
  IPartialSchemaDuplicatePayload,
  IPartialSchemaEditPayload,
  IPartialSchemaListResponse,
} from '../models/partial-schema';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import { IPartialSchemaState, IRootState } from '../models/store';

export const state = (): IPartialSchemaState => ({
  partialSchemas: [],
  partialSchemasLoading: false,
});

export const mutations: MutationTree<IPartialSchemaState> = {
  setPartialSchemas(
    state: IPartialSchemaState,
    newPartialSchemas: IPartialSchemaListResponse[],
  ): void {
    state.partialSchemas = newPartialSchemas;
  },
  setPartialSchemasLoading(state: IPartialSchemaState, loading: boolean): void {
    state.partialSchemasLoading = loading;
  },
};

export const getters: GetterTree<IPartialSchemaState, IRootState> = {
  partialSchemas(state: IPartialSchemaState): IPartialSchemaListResponse[] {
    return state.partialSchemas;
  },
  partialSchemasLoading(state: IPartialSchemaState): boolean {
    return state.partialSchemasLoading;
  },
};

export const actions: ActionTree<IPartialSchemaState, IRootState> = {
  async DUPLICATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: IPartialSchemaDuplicatePayload,
  ) {
    commit('setPartialSchemasLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const { data } = await context.$partialSchemaApi.get(payload.id);

    const createPayload: IPartialSchemaCreatePayload = {
      name: payload.name,
      viewHandle: payload.viewHandle,
      data: data.data,
    };

    dispatch('CREATE', createPayload);
  },

  async CREATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: IPartialSchemaCreatePayload,
  ) {
    commit('setPartialSchemasLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const { data } = await context.$partialSchemaApi.create(payload);

    if (payload.data) {
      const editPayload: IPartialSchemaEditPayload = {
        name: payload.name,
        format: 'json',
        schema: JSON.parse(payload.data),
      };

      await context.$partialSchemaApi.edit(
        data.partialMappingSchemaGuid,
        editPayload,
      );
    }

    dispatch('GET_PARTIAL_SCHEMAS', true);

    this.$router.push(`/partial-schemas/${data.partialMappingSchemaGuid}`);
  },

  async GET_PARTIAL_SCHEMAS(
    {
      commit,
      state,
      rootGetters,
    }: {
      commit: any;
      state: IPartialSchemaState;
      rootGetters: any;
    },
    force: boolean,
  ) {
    if (
      (force || !state.partialSchemas.length) &&
      rootGetters['tenant/currentId']
    ) {
      commit('setPartialSchemasLoading', true);

      const context = this.app.context as IEnterspeedContext;
      const { data }: IApiResponse<IPartialSchemaListResponse[]> =
        await context.$partialSchemaApi.getAll();

      commit('setPartialSchemas', data);
      commit('setPartialSchemasLoading', false);

      return data;
    } else {
      return state.partialSchemas;
    }
  },

  async EDIT(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      mappingSchemaGuid: string;
      payload: IPartialSchemaEditPayload;
    },
  ) {
    commit('setPartialSchemasLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$partialSchemaApi.edit(
        payload.mappingSchemaGuid,
        payload.payload,
      );

      dispatch('GET_PARTIAL_SCHEMAS', true);
    } catch {
      commit('setPartialSchemasLoading', false);
    }
  },

  async DELETE(
    { commit, dispatch }: { commit: any; dispatch: any },
    id: string,
  ) {
    commit('setPartialSchemasLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$partialSchemaApi.delete(id);

    dispatch('GET_PARTIAL_SCHEMAS', true);
  },
};
