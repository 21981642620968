import { parse } from '@babel/parser';
import {
  ObjectExpression,
  ObjectProperty,
  ArrayExpression,
  StringLiteral,
  FunctionExpression,
  ArrowFunctionExpression,
  BooleanLiteral,
  NumericLiteral,
  CallExpression,
  BlockStatement,
  ReturnStatement,
  ArgumentPlaceholder,
  Expression,
  JSXNamespacedName,
  SpreadElement,
  ExportDefaultDeclaration,
  ExpressionStatement,
  Node,
} from '@babel/types';

import { SchemaFormat } from '../../models/partial-schema';
import { SchemaType } from '../../models/schema';
import { ObjectResult, Result } from './result';
import { ISchemaMetadata, BaseSchemaVisitor } from './base';
import { PropertiesFunctionValidator } from './properties-function.validator';
import { PropertiesReturnStatementValidator } from './properties-return-statement.validator';
import { PropertiesReassignmentValidator } from './properties-reassignment.validator';

type SourceEntityTypeArguments = Array<
  Expression | SpreadElement | JSXNamespacedName | ArgumentPlaceholder
>;
type BabelSyntaxError = SyntaxError & {
  loc: { column: number; line: number; index: number };
};

export type LiteralValue = StringLiteral | BooleanLiteral | NumericLiteral;
export type BaseFunctionExpression =
  | ArrowFunctionExpression
  | FunctionExpression;

abstract class BaseJavascriptFormatSchemaVisitor extends BaseSchemaVisitor {
  visit(code: string): ObjectResult<ISchemaMetadata> {
    try {
      // Parse schemaISchemaMetadata
      const schemaParsingResult = this.parse(code);
      if (!schemaParsingResult.valid) {
        return ObjectResult.fail<ISchemaMetadata>(schemaParsingResult.errors);
      }

      return this.visitModuleObject(schemaParsingResult.value);
    } catch (e) {
      if (e instanceof SyntaxError) {
        const error = e as BabelSyntaxError;
        if (error.loc) {
          return ObjectResult.fail<ISchemaMetadata>([
            {
              description: error.message,
              location: { start: error.loc },
            },
          ]);
        }
      }
      return ObjectResult.fail<ISchemaMetadata>([
        { description: (e as Error).message },
      ]);
    }
  }

  abstract visitModuleObject(
    moduleObject: ObjectExpression,
  ): ObjectResult<ISchemaMetadata>;

  protected parse(code: string): ObjectResult<ObjectExpression> {
    const parsed = parse(code, { sourceType: 'module' });
    const expressionStatement = <ExportDefaultDeclaration>(
      parsed.program.body.find((f) => f.type === 'ExportDefaultDeclaration')
    );
    const objectExpression =
      expressionStatement.declaration as ObjectExpression;

    if (!objectExpression.properties) {
      return ObjectResult.fail<ObjectExpression>([
        { description: 'schemaInvalidCode' },
      ]);
    }

    return ObjectResult.ok<ObjectExpression>(objectExpression);
  }

  private extractBody(
    input: Node | Expression | null | undefined,
  ): SourceEntityTypeArguments {
    switch (input?.type) {
      case 'ArrowFunctionExpression':
        return this.extractBody((input as ArrowFunctionExpression)?.body);
      case 'FunctionExpression':
        return this.extractBody((input as FunctionExpression).body);
      case 'ExpressionStatement':
        return this.extractBody((input as ExpressionStatement).expression);
      case 'BlockStatement':
        return (input as BlockStatement).body?.flatMap((m) =>
          this.extractBody(m),
        );
      case 'CallExpression':
        return (input as CallExpression)?.arguments ?? [];
      case 'ArrayExpression':
        return (input as ArrayExpression)?.elements?.flatMap((f) =>
          this.extractBody(f),
        );
      case 'ReturnStatement':
        return this.extractBody((input as ReturnStatement).argument);
      default:
        return [];
    }
  }

  protected validateTriggers(
    moduleObject: ObjectExpression,
  ): ObjectResult<string[]> {
    const triggersProperty = moduleObject.properties
      .filter((property) => property.type === 'ObjectProperty')
      .map((property) => <ObjectProperty>property)
      .find(
        (property) =>
          property.key.type === 'Identifier' &&
          property.key.name === 'triggers',
      );
    if (!triggersProperty) {
      return ObjectResult.fail<string[]>([
        { description: 'A schema must have at least one trigger' },
      ]);
    }

    // triggers must be a function
    if (
      !['FunctionExpression', 'ArrowFunctionExpression'].includes(
        triggersProperty.value?.type,
      )
    ) {
      return ObjectResult.fail<string[]>([
        {
          description: 'Triggers must be a function',
          location: triggersProperty.loc
            ? { ...triggersProperty.loc }
            : undefined,
        },
      ]);
    }

    const triggerEntityTypeNodes = this.extractBody(triggersProperty.value);
    const sourceEntityTypes = [
      ...new Set<string>(this.extractSourceEntityTypes(triggerEntityTypeNodes)),
    ];

    if (sourceEntityTypes.length < 1) {
      return ObjectResult.fail<string[]>([
        {
          description: 'Triggers must have a group and an entity type',
          location: triggersProperty.loc
            ? { ...triggersProperty.loc }
            : undefined,
        },
      ]);
    }

    return ObjectResult.ok<string[]>(sourceEntityTypes);
  }

  private extractSourceEntityTypes(
    entityTypeArguments: SourceEntityTypeArguments,
  ): string[] {
    return entityTypeArguments
      .filter((f) => f.type === 'ArrayExpression')
      .map((m) => <ArrayExpression>m)
      .flatMap((m) => m.elements.filter((f) => f?.type === 'StringLiteral'))
      .map((m) => <StringLiteral>m)
      .map((m) => m.value);
  }

  protected validateProperties(moduleObject: ObjectExpression): Result {
    const propertiesFunctionProperty = moduleObject.properties
      .filter((property) => property.type === 'ObjectProperty')
      .map((property) => <ObjectProperty>property)
      .find(
        (property) =>
          property.key.type === 'Identifier' &&
          property.key.name === 'properties',
      );
    if (!propertiesFunctionProperty) {
      return ObjectResult.fail<string[]>([
        { description: 'javascriptSchemaPropertiesMustBeAFunction' },
      ]);
    }

    const validators = [
      new PropertiesFunctionValidator(),
      new PropertiesReturnStatementValidator(),
      new PropertiesReassignmentValidator(),
    ];

    const errors = validators.flatMap((v) =>
      v.validate(propertiesFunctionProperty),
    );

    if (errors.length) {
      return Result.fail(errors);
    }

    return Result.ok();
  }
}

export class JavascriptCollectionSchemaVisitor extends BaseJavascriptFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return 'javascript';
  }

  get schemaTypes(): SchemaType[] {
    return ['collection'];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitModuleObject(
    moduleObject: ObjectExpression,
  ): ObjectResult<ISchemaMetadata> {
    const triggerSourceEntityTypesExtractionResult =
      this.validateTriggers(moduleObject);
    const itemsValidationResult = this.validateCollectionItems(moduleObject);

    if (
      !triggerSourceEntityTypesExtractionResult.valid ||
      !itemsValidationResult.valid
    ) {
      return ObjectResult.fail<ISchemaMetadata>([
        ...(triggerSourceEntityTypesExtractionResult.errors ?? []),
        ...(itemsValidationResult.errors ?? []),
      ]);
    }

    return ObjectResult.ok<ISchemaMetadata>({
      sourceEntityTypes: triggerSourceEntityTypesExtractionResult.value ?? [],
    });
  }

  protected validateCollectionItems(moduleObject: ObjectExpression): Result {
    const propertiesFunctionProperty = moduleObject.properties
      .filter((property) => property.type === 'ObjectProperty')
      .map((property) => <ObjectProperty>property)
      .find(
        (property) =>
          property.key.type === 'Identifier' && property.key.name === 'items',
      );
    if (!propertiesFunctionProperty) {
      return ObjectResult.fail<string[]>([
        { description: 'javascriptSchemaCollectionItemsMustBeAFunction' },
      ]);
    }

    const validators = [
      new PropertiesFunctionValidator(),
      new PropertiesReturnStatementValidator(),
      new PropertiesReassignmentValidator(),
    ];

    const errors = validators.flatMap((v) =>
      v.validate(propertiesFunctionProperty),
    );

    if (errors.length) {
      return Result.fail(errors);
    }

    return Result.ok();
  }
}

export class JavascriptNormalSchemaVisitor extends BaseJavascriptFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return 'javascript';
  }

  get schemaTypes(): SchemaType[] {
    return ['normal'];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitModuleObject(
    moduleObject: ObjectExpression,
  ): ObjectResult<ISchemaMetadata> {
    const triggerSourceEntityTypesExtractionResult =
      this.validateTriggers(moduleObject);
    const propertiesValidationResult = this.validateProperties(moduleObject);

    if (
      !triggerSourceEntityTypesExtractionResult.valid ||
      !propertiesValidationResult.valid
    ) {
      return ObjectResult.fail<ISchemaMetadata>([
        ...(triggerSourceEntityTypesExtractionResult.errors ?? []),
        ...(propertiesValidationResult.errors ?? []),
      ]);
    }

    return ObjectResult.ok<ISchemaMetadata>({
      sourceEntityTypes: triggerSourceEntityTypesExtractionResult.value ?? [],
    });
  }
}

export class JavascriptPartialSchemaVisitor extends BaseJavascriptFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return 'javascript';
  }

  get schemaTypes(): SchemaType[] {
    return ['partial'];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitModuleObject(
    moduleObject: ObjectExpression,
  ): ObjectResult<ISchemaMetadata> {
    const propertiesValidationResult = this.validateProperties(moduleObject);
    if (!propertiesValidationResult.valid) {
      return ObjectResult.fail<ISchemaMetadata>(
        propertiesValidationResult.errors,
      );
    }

    return ObjectResult.ok<ISchemaMetadata>({
      sourceEntityTypes: [],
    });
  }
}
