import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import {
  IEnvironmentClientCreatePayload,
  IEnvironmentClientEditPayload,
  IEnvironmentClientResponse,
} from '../models/environment-client';
import { IRootState, IEnvironmentClientsState } from '~/src/models/store';

export const state = (): IEnvironmentClientsState => ({
  environmentClients: [],
  environmentClientsLoading: false,
  newAccessKeyRowId: null,
});

export const mutations: MutationTree<IEnvironmentClientsState> = {
  setEnvironmentClients(
    state: IEnvironmentClientsState,
    newEnvironmentClients: IEnvironmentClientResponse[],
  ): void {
    state.environmentClients = newEnvironmentClients;
  },
  setEnvironmentClientsLoading(
    state: IEnvironmentClientsState,
    loading: boolean,
  ): void {
    state.environmentClientsLoading = loading;
  },
  setNewAccessKeyRowId(
    state: IEnvironmentClientsState,
    id: string | null,
  ): void {
    state.newAccessKeyRowId = id;
  },
  deleteEnvironmentClient(
    state: IEnvironmentClientsState,
    clientToDelete: { environmentGuid: string; clientGuid: string },
  ): void {
    state.environmentClients = state.environmentClients.filter(
      (client) => client.id.clientGuid !== clientToDelete.clientGuid,
    );
  },
};

export const getters: GetterTree<IEnvironmentClientsState, IRootState> = {
  environmentClients(
    state: IEnvironmentClientsState,
  ): IEnvironmentClientResponse[] {
    return state.environmentClients;
  },
  environmentClientsLoading(state: IEnvironmentClientsState): boolean {
    return state.environmentClientsLoading;
  },
  newAccessKeyRowId(state: IEnvironmentClientsState): string | null {
    return state.newAccessKeyRowId;
  },
};

export const actions: ActionTree<IEnvironmentClientsState, IRootState> = {
  async CREATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      payload: IEnvironmentClientCreatePayload;
      environmentId: string;
    },
  ) {
    commit('setEnvironmentClientsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    const { data } = await context.$environmentClientApi.create(
      payload.environmentId,
      payload.payload,
    );

    await dispatch('GET_ENVIRONMENT_CLIENTS', true);

    return data.idValue;
  },

  async EDIT(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      environmentId: string;
      clientId: string;
      payload: IEnvironmentClientEditPayload;
      newAccessKeyRowId: string | null;
    },
  ) {
    commit('setEnvironmentClientsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$environmentClientApi.edit(
      payload.environmentId,
      payload.clientId,
      payload.payload,
    );

    dispatch('GET_ENVIRONMENT_CLIENTS', true);

    if (payload.newAccessKeyRowId) {
      commit('setNewAccessKeyRowId', payload.newAccessKeyRowId);
      setTimeout(() => {
        commit('setNewAccessKeyRowId', null);
      }, 1000);
    }
  },

  async DELETE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: { environmentGuid: string; clientGuid: string },
  ) {
    commit('setEnvironmentClientsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$environmentClientApi.delete(
      payload.environmentGuid,
      payload.clientGuid,
    );

    commit('deleteEnvironmentClient', payload);
    commit('setEnvironmentClientsLoading', false);
  },

  async GET_ENVIRONMENT_CLIENTS(
    { commit, state }: { commit: any; state: IEnvironmentClientsState },
    force: boolean,
  ) {
    if (force || !state.environmentClients.length) {
      commit('setEnvironmentClientsLoading', true);

      const context = this.app.context as IEnterspeedContext;
      const { data }: IApiResponse<IEnvironmentClientResponse[]> =
        await context.$environmentClientApi.getAll();

      commit('setEnvironmentClients', data);

      commit('setEnvironmentClientsLoading', false);
      return data;
    } else {
      return state.environmentClients;
    }
  },
};
