import { NuxtAxiosInstance } from '@nuxtjs/axios';

export default ($axios: NuxtAxiosInstance) => ({
  get() {
    return $axios.get('/api/v1/invites/pending');
  },

  acceptInvite(id: string) {
    return $axios.put(`/api/v1/invites/${id}/_accept`);
  },

  declineInvite(id: string) {
    return $axios.put(`/api/v1/invites/${id}/_decline`);
  },
});
