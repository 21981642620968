import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import ISelectOption from '../models/select-input';
import { IFavorites } from '../models/favorites';
import { IRootState, IFavoritesState } from '~/src/models/store';

const localItems = window.localStorage.getItem('pinned');

export const state = (): IFavoritesState => ({
  favorites: { ...(localItems ? JSON.parse(localItems) : {}) },
});

export const mutations: MutationTree<IFavoritesState> = {
  setPinnedSchemas(state: IFavoritesState, newData: IFavorites): void {
    state.favorites = newData;
  },
};

export const getters: GetterTree<IFavoritesState, IRootState> = {
  favorites(state: IFavoritesState, _, rootState: any): ISelectOption[] {
    const currentTenantId = rootState.tenant.currentTenantId?.replace(
      'gid://Tenant/',
      '',
    );

    return state.favorites[currentTenantId];
  },
};

export const actions: ActionTree<IFavoritesState, IRootState> = {
  TOGGLE_FAVORITE_PINNED(
    { commit, rootState }: { commit: any; rootState: any },
    item: ISelectOption,
  ) {
    let favorites: IFavorites = {};

    const currentTenantId = rootState.tenant.currentTenantId?.replace(
      'gid://Tenant/',
      '',
    );

    const localItems = window.localStorage.getItem('pinned');
    if (localItems) {
      favorites = { ...JSON.parse(localItems) };
    }

    const currentTenantsFavorites = favorites[currentTenantId];

    if (currentTenantsFavorites) {
      if (
        currentTenantsFavorites.length &&
        currentTenantsFavorites.find((x) => x.value === item.value)
      ) {
        favorites[currentTenantId] = [
          ...currentTenantsFavorites.filter((x) => x.value !== item.value),
        ];
      } else {
        currentTenantsFavorites.push(item);
      }
    } else {
      favorites[currentTenantId] = [item];
    }

    window.localStorage.setItem('pinned', JSON.stringify(favorites));
    commit('setPinnedSchemas', favorites);
  },
};
