import { Context, Inject } from '@nuxt/types/app';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDUser } from 'launchdarkly-js-client-sdk';
import Vue from 'vue';

export const initialize = () => {
  const ldClient = LDClient.initialize(
    '61dda3043153d714a96a3fe6',
    {
      anonymous: true,
    },
    {},
  );
  const $ld = Vue.observable({
    ldClient,
    identify(newUser: LDUser) {
      return new Promise((resolve) => {
        this.ready = false;
        ldClient.identify(newUser).then(() => {
          this.ready = true;
          resolve(true);
        });
      });
    },
    flags: {},
    ready: false,
    error: null,
  });

  ldClient.on('ready', () => {
    $ld.flags = ldClient.allFlags();
    $ld.ready = true;
  });

  ldClient.on('change', (changes) => {
    const flattenedFlags = Object.fromEntries(
      Object.keys(changes).map((key) => [key, changes[key].current]),
    );
    $ld.flags = {
      ...$ld.flags,
      ...flattenedFlags,
    };
  });

  ldClient.on('error', (e) => {
    $ld.error = e;
    throw e;
  });

  return $ld;
};

export default (_: Context, inject: Inject) => {
  const $ld = initialize();
  inject('ld', $ld);
};
