import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { ISourceGroupsCreatePayload } from '../models/sourceGroups';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/source-groups');
  },

  create(payload: ISourceGroupsCreatePayload) {
    return $axios.post('/api/v1/tenant/source-groups', payload);
  },

  edit(id: string, payload: ISourceGroupsCreatePayload) {
    return $axios.put('/api/v1/tenant/source-groups/' + id, payload);
  },

  delete(id: string) {
    return $axios.delete('/api/v1/tenant/source-groups/' + id);
  },
});
