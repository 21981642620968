import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import {
  IDomainCreatePayload,
  IDomainEditPayload,
  IDomainResponse,
} from '../models/domain';
import { IRootState, IDomainState } from '~/src/models/store';

export const state = (): IDomainState => ({
  domains: [],
  domainsLoading: false,
});

export const mutations: MutationTree<IDomainState> = {
  setDomains(state: IDomainState, newDomains: IDomainResponse[]): void {
    state.domains = newDomains;
  },
  setDomainsLoading(state: IDomainState, loading: boolean): void {
    state.domainsLoading = loading;
  },
  deleteDomain(state: IDomainState, deletedDomainGuid: string): void {
    state.domains = state.domains.filter(
      (d) => d.id.idValue !== `gid://Domain/${deletedDomainGuid}`,
    );
  },
};

export const getters: GetterTree<IDomainState, IRootState> = {
  domains(state: IDomainState): IDomainResponse[] {
    return state.domains;
  },
  domainsLoading(state: IDomainState): boolean {
    return state.domainsLoading;
  },
};

export const actions: ActionTree<IDomainState, IRootState> = {
  async CREATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: IDomainCreatePayload,
  ) {
    commit('setDomainsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$domainApi.create(payload);

    dispatch('GET_DOMAINS', true);
  },

  async GET_DOMAINS(
    { commit, state }: { commit: any; state: IDomainState },
    force: boolean,
  ) {
    if (force || !state.domains.length) {
      commit('setDomainsLoading', true);

      const context = this.app.context as IEnterspeedContext;
      const { data }: IApiResponse<IDomainResponse[]> =
        await context.$domainApi.getAll();

      commit('setDomains', data);

      commit('setDomainsLoading', false);
      return data;
    } else {
      return state.domains;
    }
  },

  async EDIT(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      domainGuid: string;
      payload: IDomainEditPayload;
    },
  ) {
    commit('setDomainsLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$domainApi.edit(payload.domainGuid, payload.payload);

      dispatch('GET_DOMAINS', true);
    } catch {
      commit('setDomainsLoading', false);
    }
  },

  async DELETE({ commit }: { commit: any; dispatch: any }, domainGuid: string) {
    commit('setDomainsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$domainApi.delete(domainGuid);
    commit('deleteDomain', domainGuid);

    commit('setDomainsLoading', false);
  },
};
