import {
  INotificationToastConfig,
  INotificationDetails,
} from '../../models/notification';
import NotificationType from '../../constants/ui/notificationType';

let notificationTimeOut: NodeJS.Timeout | null = null;

export const displayNotification = (
  store: any,
  message: string,
  type: NotificationType,
  details?: INotificationDetails,
) => {
  const notificationToastConfig: INotificationToastConfig = {
    message,
    type,
    details,
  };

  store.dispatch('notification/RESET_NOTIFICATION_TOAST');

  setTimeout(() => {
    if (notificationTimeOut !== null) {
      clearTimeout(notificationTimeOut);
    }

    store.dispatch(
      'notification/SET_NOTIFICATION_TOAST',
      notificationToastConfig,
    );
    store.dispatch('notification/SET_NOTIFICATION_TOAST_STATE', true);

    notificationTimeOut = setTimeout(() => {
      store.dispatch('notification/RESET_NOTIFICATION_TOAST');
    }, 7500);
  });
};
