import { ISchemaReponseId } from './schema';
import ISelectOption from './select-input';

export interface IBulkDeploy {
  id: string;
  mappingSchemaGuid: string;
  name: string;
  alias: string;
  version: ISelectOption;
  deployVersion: ISelectOption | null;
  currentVersion: number | null;
  state: ChangeState;
  exclude?: boolean;
  schemaType: SchemaType;
  useVersionFromDifferentEnv: boolean;
  useVersionFromDifferentEnvClass: boolean;
}

export enum ChangeState {
  UPDATED = 'Updated',
  ADDED = 'Added',
  UNCHANGED = 'Unchanged',
  UNPUBLISHED = 'Unpublished',
  REDEPLOY = 'Redeploy',
}

export enum SchemaType {
  CURRENT = 'current',
  PENDING = 'pending',
}

export enum IBulkDeployStep {
  DEPLOYING = 'DEPLOYING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface IBulkDeployResponse {
  current: IBulkDeployResponseCurrent;
  pending: IBulkDeployResponsePending;
}

export interface IBulkDeployResponseCurrent {
  schemas: IBulkDeployResponseCurrentSchema[];
}

export interface IBulkDeployResponsePending {
  schemas: IBulkDeployResponsePendingSchema[];
}

export interface IBulkDeployResponseCurrentSchema {
  id: ISchemaReponseId;
  name: string;
  alias: string;
  version: number;
}

export interface IBulkDeployResponsePendingSchema
  extends IBulkDeployResponseCurrentSchema {
  state: string;
}

export interface IBulkDeployPostPayload {
  deploys: IBulkDeploy[];
  environmentId: string;
}
