import { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  IPartialSchemaCreatePayload,
  IPartialSchemaEditPayload,
} from '../models/partial-schema';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/partial-mappings');
  },

  get(id: string) {
    return $axios.get('/api/v1/tenant/partial-mappings/' + id);
  },

  create(payload: IPartialSchemaCreatePayload) {
    return $axios.post('/api/v1/tenant/partial-mappings/', payload);
  },

  edit(id: string, payload: IPartialSchemaEditPayload) {
    return $axios.put('/api/v1/tenant/partial-mappings/' + id, payload);
  },

  delete(id: string) {
    return $axios.delete('/api/v1/tenant/partial-mappings/' + id);
  },
});
