import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IInspectRoutePayload } from '../models/routes';

export default ($axios: NuxtAxiosInstance) => ({
  inspect(payload: IInspectRoutePayload, continuationToken?: string) {
    const headers = continuationToken
      ? { 'x-continuation-token': continuationToken }
      : undefined;
    return $axios.post('/api/v1/tenant/routes/_inspect', payload, {
      headers,
    });
  },
});
