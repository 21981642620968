import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import ISelectOption from '../models/select-input';
import {
  IEditorFeedback,
  ISchemaCreatePayload,
  ISchemaData,
  ISchemaDuplicatePayload,
  ISchemaEditPayload,
  ISchemaListResponse,
  ISchemaVersionEditPayload,
  SchemaFormat,
  SchemaType,
} from '../models/schema';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import { SchemaVisitor } from '../utils/schema-visitors/visitor';
import { ISchemaMetadata } from '../utils/schema-visitors/base';
import { ErrorDescriptor } from '../utils/schema-visitors/result';
import { IRootState, ISchemaState } from '~/src/models/store';

export const state = (): ISchemaState => ({
  data: '',
  schemaType: undefined,
  schemaVersionFormat: undefined,
  validData: '',
  savedData: '',
  schemaMetadata: undefined,
  schemas: [],
  schemasLoading: false,
  editorFeedback: undefined
});

export const mutations: MutationTree<ISchemaState> = {
  setData(state: ISchemaState, newData: string): void {
    state.data = newData;
  },
  setSchemaVersionFormat(
    state: ISchemaState,
    format: SchemaFormat | undefined,
  ): void {
    state.schemaVersionFormat = format;
  },
  setSchemaType(state: ISchemaState, type: SchemaType | undefined): void {
    state.schemaType = type;
  },
  setSavedData(state: ISchemaState, newData: string): void {
    state.savedData = newData;
  },
  setSchemaMetadata(
    state: ISchemaState,
    newMetadata: ISchemaMetadata | undefined,
  ): void {
    state.schemaMetadata = newMetadata;
  },
  setValidData(state: ISchemaState, newData: string): void {
    state.validData = newData;
  },
  setSchemas(state: ISchemaState, newSchemas: ISchemaListResponse[]): void {
    state.schemas = newSchemas;
  },
  setSchemasLoading(state: ISchemaState, loading: boolean): void {
    state.schemasLoading = loading;
  },
  deleteSchema(state: ISchemaState, mappingSchemaGuid: string): void {
    state.schemas = state.schemas.filter(
      (schema) => schema.id.mappingSchemaGuid !== mappingSchemaGuid,
    );
  },
  setEditorFeedback(state: ISchemaState, newData: IEditorFeedback): void {
    state.editorFeedback = newData;
  },
};

export const getters: GetterTree<ISchemaState, IRootState> = {
  data(state: ISchemaState): string {
    return state.data;
  },
  validData(state: ISchemaState): string {
    return state.validData;
  },
  hasChanges(state: ISchemaState): boolean {
    return state.data !== state.savedData;
  },
  isSchemaValid(state: ISchemaState, _, __, rootGetters: any): boolean {
    const schemaVisitorResult = SchemaVisitor(
      state.data,
      state.schemaType,
      state.schemaVersionFormat,
      state.editorFeedback,
      rootGetters['tenant/current'],
    );
    return schemaVisitorResult.valid;
  },
  schemaErrors(
    state: ISchemaState,
    _,
    __,
    rootGetters: any,
  ): ErrorDescriptor[] {
    const schemaVisitorResult = SchemaVisitor(
      state.data,
      state.schemaType,
      state.schemaVersionFormat,
      state.editorFeedback,
      rootGetters['tenant/current'],
    );
    return schemaVisitorResult.errors ?? [];
  },
  metadata(state: ISchemaState): ISchemaMetadata | undefined {
    return state.schemaMetadata;
  },
  schemas(state: ISchemaState): ISchemaListResponse[] {
    return state.schemas;
  },
  schemasLoading(state: ISchemaState): boolean {
    return state.schemasLoading;
  },
  editorFeedback(state: ISchemaState): IEditorFeedback | undefined {
    return state.editorFeedback;
  },
};

export const actions: ActionTree<ISchemaState, IRootState> = {
  SET_SCHEMA_DATA(
    { commit, rootGetters }: { commit: any; rootGetters: any },
    newData: ISchemaData,
  ) {
    commit('setSchemaType', newData.type);
    commit('setSchemaVersionFormat', newData.format);
    commit('setData', newData.code);
    commit('setEditorFeedback', newData.editorFeedback);

    const schemaVisitorResult = SchemaVisitor(
      newData.code,
      newData.type,
      newData.format,
      newData.editorFeedback,
      rootGetters['tenant/current'],
    );
    if (schemaVisitorResult.valid) {
      commit('setValidData', newData.code);
      commit('setSchemaMetadata', schemaVisitorResult.value);
    }
  },

  SET_SAVED_SCHEMA_DATA({ commit }: { commit: any }, newData: string) {
    commit('setSavedData', newData);
  },

  RESET_SCHEMA_DATA({ commit }: { commit: any }) {
    commit('setData', '');
    commit('setSchemaMetadata', undefined);
    commit('setValidData', '');
    commit('setSavedData', '');
    commit('setEditorFeedback', undefined);
  },

  TOGGLE_SCHEMA_PINNED({ commit }: { commit: any }, item: ISelectOption) {
    let items: ISelectOption[] = [];

    const localItems = window.localStorage.getItem('pinned');
    if (localItems) {
      items = [...JSON.parse(localItems)];
    }

    if (items.find((x) => x.value === item.value)) {
      items = items.filter((x) => x.value === item.value);
    } else {
      items.push(item);
    }
    window.localStorage.setItem('pinned', JSON.stringify(items));
    commit('setPinnedSchemas', items);
  },

  async DUPLICATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: ISchemaDuplicatePayload,
  ) {
    commit('setSchemasLoading', true);

    const context = this.app.context as IEnterspeedContext;

    const { data } = await context.$schemaApi.get(payload.id);

    const createPayload: ISchemaCreatePayload = {
      name: payload.name,
      viewHandle: payload.viewHandle,
      format: data.version.format,
      type: data.type,
      data:
        data.version.format === 'javascript'
          ? Buffer.from(data.version.data, 'base64').toString('utf8')
          : data.version.data,
    };

    dispatch('CREATE', createPayload);
  },

  async CREATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: ISchemaCreatePayload,
  ) {
    commit('setSchemasLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      const { data } = await context.$schemaApi.create(payload);

      if (payload.data) {
        const editPayload: ISchemaVersionEditPayload = {
          name: payload.name,
          format: payload.format,
          schema:
            payload.format === 'javascript'
              ? Buffer.from(payload.data, 'utf8').toString('base64')
              : JSON.parse(payload.data),
        };

        await context.$schemaApi.editVersion(
          data.mappingSchemaGuid,
          data.version ? data.version : 1,
          editPayload,
        );
      }

      dispatch('GET_SCHEMAS', true);

      this.$router.push(`/schemas/${data.mappingSchemaGuid}`);
    } catch {
      commit('setSchemasLoading', false);
    }
  },

  async GET_SCHEMAS(
    {
      commit,
      state,
      rootGetters,
    }: { commit: any; state: ISchemaState; rootGetters: any },
    force: boolean,
  ) {
    if ((force || !state.schemas.length) && rootGetters['tenant/currentId']) {
      commit('setSchemasLoading', true);

      const context = this.app.context as IEnterspeedContext;
      const { data }: IApiResponse<ISchemaListResponse[]> =
        await context.$schemaApi.getAll();

      commit('setSchemas', data);
      commit('setSchemasLoading', false);

      return data;
    } else {
      return state.schemas;
    }
  },

  async EDIT(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      schemaGuid: string;
      payload: ISchemaEditPayload;
    },
  ) {
    commit('setSchemasLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$schemaApi.edit(payload.schemaGuid, payload.payload);

      dispatch('GET_SCHEMAS', true);
    } catch {
      commit('setSchemasLoading', false);
    }
  },

  async DELETE({ commit }: { commit: any }, mappingSchemaGuid: string) {
    const context = this.app.context as IEnterspeedContext;
    await context.$schemaApi.delete(mappingSchemaGuid);

    commit('deleteSchema', mappingSchemaGuid);
  },
};
