import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import {
  IManagementClientCreatePayload,
  IManagementClientEditPayload,
  IManagementClientResponse,
} from '../models/management-client';
import { IRootState, IManagementClientsState } from '../models/store';

export const state = (): IManagementClientsState => ({
  managementClients: [],
  managementClientsLoading: false,
  newAccessKeyRowId: null,
});

export const mutations: MutationTree<IManagementClientsState> = {
  setManagementClients(
    state: IManagementClientsState,
    newManagementClients: IManagementClientResponse[],
  ): void {
    state.managementClients = newManagementClients;
  },
  setManagementClientsLoading(
    state: IManagementClientsState,
    loading: boolean,
  ): void {
    state.managementClientsLoading = loading;
  },
  setNewAccessKeyRowId(
    state: IManagementClientsState,
    id: string | null,
  ): void {
    state.newAccessKeyRowId = id;
  },
};

export const getters: GetterTree<IManagementClientsState, IRootState> = {
  managementClients(
    state: IManagementClientsState,
  ): IManagementClientResponse[] {
    return state.managementClients;
  },
  managementClientsLoading(state: IManagementClientsState): boolean {
    return state.managementClientsLoading;
  },
  newAccessKeyRowId(state: IManagementClientsState): string | null {
    return state.newAccessKeyRowId;
  },
};

export const actions: ActionTree<IManagementClientsState, IRootState> = {
  async CREATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: IManagementClientCreatePayload,
  ) {
    commit('setManagementClientsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    const { data } = await context.$managementClientApi.create(payload);

    await dispatch('GET_MANAGEMENT_CLIENTS', true);

    return data.idValue;
  },

  async EDIT(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      managementClientGuid: string;
      payload: IManagementClientEditPayload;
    },
  ) {
    commit('setManagementClientsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$managementClientApi.edit(
      payload.managementClientGuid,
      payload.payload,
    );

    dispatch('GET_MANAGEMENT_CLIENTS', true);
  },

  async DELETE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: { managementClientGuid: string },
  ) {
    commit('setManagementClientsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$managementClientApi.delete(payload.managementClientGuid);

    dispatch('GET_MANAGEMENT_CLIENTS', true);
  },

  async GET_MANAGEMENT_CLIENTS(
    { commit, state }: { commit: any; state: IManagementClientsState },
    force: boolean,
  ) {
    if (force || !state.managementClients.length) {
      commit('setManagementClientsLoading', true);

      const context = this.app.context as IEnterspeedContext;
      const { data }: IApiResponse<IManagementClientResponse[]> =
        await context.$managementClientApi.getAll();

      commit('setManagementClients', data);

      commit('setManagementClientsLoading', false);
      return data;
    } else {
      return state.managementClients;
    }
  },

  async REGENERATE_ACCESS_KEY(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      managementClientGuid: string;
      newAccessKeyRowId: string;
    },
  ) {
    commit('setManagementClientsLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$managementClientApi.regenerateAccessKey(
      payload.managementClientGuid,
    );

    commit('setNewAccessKeyRowId', payload.newAccessKeyRowId);
    setTimeout(() => {
      commit('setNewAccessKeyRowId', null);
    }, 1000);

    dispatch('GET_MANAGEMENT_CLIENTS', true);
  },
};
