import { render, staticRenderFns } from "./EsUserSettingsDropdown.vue?vue&type=template&id=e652eb38&scoped=true&"
import script from "./EsUserSettingsDropdown.vue?vue&type=script&lang=ts&"
export * from "./EsUserSettingsDropdown.vue?vue&type=script&lang=ts&"
import style0 from "./EsUserSettingsDropdown.vue?vue&type=style&index=0&id=e652eb38&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e652eb38",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheAvatar: require('/app/src/components/global/TheAvatar.vue').default,EsNotificationBubble: require('/app/src/components/common/EsNotificationBubble.vue').default,EsLink: require('/app/src/components/common/EsLink.vue').default,EsPopover: require('/app/src/components/common/EsPopover.vue').default})
