import { SchemaFormat } from '../../models/partial-schema';
import { SchemaType } from '../../models/schema';
import { ITenant } from '../../models/tenant';
import { ObjectResult, Result } from './result';
import { BaseSchemaVisitor, ISchemaMetadata } from './base';
import { ISchemaCode } from '~/src/models/schema';

abstract class BaseJsonFormatSchemaVisitor extends BaseSchemaVisitor {
  visit(code: string): ObjectResult<ISchemaMetadata> {
    try {
      // Parse schema
      const schemaParsingResult = this.parseSchema(code);
      if (!schemaParsingResult.valid) {
        return ObjectResult.fail<ISchemaMetadata>(schemaParsingResult.errors);
      }

      return this.visitSchema(schemaParsingResult.value);
    } catch {
      return ObjectResult.fail<ISchemaMetadata>([
        { description: 'schemaInvalidCode' },
      ]);
    }
  }

  abstract visitSchema(schema: ISchemaCode): ObjectResult<ISchemaMetadata>;

  protected parseSchema(code: string): ObjectResult<ISchemaCode> {
    try {
      const schema: ISchemaCode = JSON.parse(code);
      if (!schema) {
        return ObjectResult.fail<ISchemaCode>([
          { description: 'schemaInvalid' },
        ]);
      }

      return ObjectResult.ok<ISchemaCode>(schema);
    } catch {
      return ObjectResult.fail<ISchemaCode>([
        { description: 'schemaInvalidCode' },
      ]);
    }
  }
}

export class JsonSourceEntityTypesSchemaVisitor extends BaseJsonFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return 'json';
  }

  get schemaTypes(): SchemaType[] {
    return ['normal'];
  }

  get usingSourceGroup(): boolean {
    return false;
  }

  visitSchema(schema: ISchemaCode): ObjectResult<ISchemaMetadata> {
    // Validate source entity types
    const sourceEntityTypesValidationResult =
      this.validateSourceEntityTypes(schema);
    if (!sourceEntityTypesValidationResult.valid) {
      return ObjectResult.fail<ISchemaMetadata>(
        sourceEntityTypesValidationResult.errors,
      );
    }

    return ObjectResult.ok<ISchemaMetadata>({
      sourceEntityTypes: this.extractSourceEntityTypes(schema),
    });
  }

  validateSourceEntityTypes(schema: ISchemaCode): Result {
    if (!schema.sourceEntityTypes?.length) {
      return Result.fail([{ description: 'sourceEntityTypesInvalid' }]);
    }

    return Result.ok();
  }

  extractSourceEntityTypes(schema: ISchemaCode): string[] {
    return schema.sourceEntityTypes;
  }
}

export class JsonSourceGroupTriggerSchemaVisitor extends BaseJsonFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return 'json';
  }

  get schemaTypes(): SchemaType[] {
    return ['normal', 'collection'];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitSchema(schema: ISchemaCode): ObjectResult<ISchemaMetadata> {
    // Validate triggers
    const triggerValidationResult = this.validateTriggers(schema);
    if (!triggerValidationResult.valid) {
      return ObjectResult.fail<ISchemaMetadata>(triggerValidationResult.errors);
    }

    return ObjectResult.ok<ISchemaMetadata>({
      sourceEntityTypes: this.extractSourceEntityTypes(schema),
    });
  }

  validateTriggers(schema: ISchemaCode): Result {
    if (!schema.triggers) {
      return Result.fail([{ description: 'schemaTriggersMissing' }]);
    }

    if (typeof schema.triggers !== 'object' || Array.isArray(schema.triggers)) {
      return Result.fail([{ description: 'schemaTriggersMustBeAnObject' }]);
    }

    if (Object.keys(schema.triggers).length === 0) {
      return Result.fail([
        { description: 'schemaTriggersMustHaveAGroupAndAnEntityType' },
      ]);
    }

    return Result.ok();
  }

  extractSourceEntityTypes(schema: ISchemaCode): string[] {
    const removeDuplicates = new Set(
      Object.values(<string[]>schema.triggers).flat(),
    );
    return [...removeDuplicates];
  }
}

export class JsonPartialSchemaValidator extends BaseJsonFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return 'json';
  }

  get schemaTypes(): SchemaType[] {
    return ['partial'];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  canVisit(type: SchemaType, format: SchemaFormat, tenant: ITenant): boolean {
    return super.canVisit(type, format, tenant)
      ? ['mixed', 'enabled'].includes(
          tenant.partialSchemaFirstClassCitizenOption,
        )
      : false;
  }

  visitSchema(_: ISchemaCode): ObjectResult<ISchemaMetadata> {
    return ObjectResult.ok<ISchemaMetadata>({
      sourceEntityTypes: [],
    });
  }
}
