import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { IDomainCreatePayload, IDomainEditPayload } from '../models/domain';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/domains');
  },
  create(payload: IDomainCreatePayload) {
    return $axios.post('/api/v1/tenant/domains', payload);
  },
  edit(id: string, payload: IDomainEditPayload) {
    return $axios.put('/api/v1/tenant/domains/' + id, payload);
  },
  delete(id: string) {
    return $axios.delete('/api/v1/tenant/domains/' + id);
  },
});
