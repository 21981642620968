import { MutationTree, GetterTree, ActionTree } from 'vuex/types';
import { IEnterspeedContext } from '../models/es-app';
import { IApiResponse } from '../models/api';
import {
  ISourceCreatePayload,
  ISourceEditPayload,
  ISourceResponse,
} from '../models/source';
import { IRootState, ISourceState } from '../models/store';

export const state = (): ISourceState => ({
  sources: [],
  sourcesLoading: false,
  newAccessKeyRowId: null,
});

export const mutations: MutationTree<ISourceState> = {
  setSources(state: ISourceState, newSources: ISourceResponse[]): void {
    state.sources = newSources;
  },
  setSourcesLoading(state: ISourceState, loading: boolean): void {
    state.sourcesLoading = loading;
  },
  setNewAccessKeyRowId(state: ISourceState, id: string | null): void {
    state.newAccessKeyRowId = id;
  },
};

export const getters: GetterTree<ISourceState, IRootState> = {
  sources(state: ISourceState): ISourceResponse[] {
    return state.sources;
  },
  sourcesLoading(state: ISourceState): boolean {
    return state.sourcesLoading;
  },
  newAccessKeyRowId(state: ISourceState): string | null {
    return state.newAccessKeyRowId;
  },
};

export const actions: ActionTree<ISourceState, IRootState> = {
  async CREATE(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: ISourceCreatePayload,
  ) {
    commit('setSourcesLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$sourceApi.create(payload);

    dispatch('GET_SOURCES', true);
  },

  async GET_SOURCES(
    { commit, state }: { commit: any; state: ISourceState },
    force: boolean,
  ) {
    if (force || !state.sources.length) {
      commit('setSourcesLoading', true);

      const context = this.app.context as IEnterspeedContext;
      const { data }: IApiResponse<ISourceResponse[]> =
        await context.$sourceApi.getAll();

      commit('setSources', data);
      commit('setSourcesLoading', false);
      return data;
    }
    return state.sources;
  },

  async EDIT(
    { commit, dispatch }: { commit: any; dispatch: any },
    payload: {
      sourceGuid: string;
      payload: ISourceEditPayload;
      newAccessKeyRowId: string | null;
    },
  ) {
    commit('setSourcesLoading', true);

    try {
      const context = this.app.context as IEnterspeedContext;
      await context.$sourceApi.edit(payload.sourceGuid, payload.payload);

      dispatch('GET_SOURCES', true);
      dispatch('source-groups/GET_SOURCE_GROUPS', null, { root: true });

      if (payload.newAccessKeyRowId) {
        commit('setNewAccessKeyRowId', payload.newAccessKeyRowId);
        setTimeout(() => {
          commit('setNewAccessKeyRowId', null);
        }, 1000);
      }
    } catch {
      commit('setSourcesLoading', false);
    }
  },

  async DELETE(
    { commit, dispatch }: { commit: any; dispatch: any },
    id: string,
  ) {
    commit('setSourcesLoading', true);

    const context = this.app.context as IEnterspeedContext;
    await context.$sourceApi.delete(id);

    dispatch('GET_SOURCES', true);
  },

  async REPROCESS_ENTITY(_, sourceEntityIds: string[]) {
    const context = this.app.context as IEnterspeedContext;
    await context.$sourceApi.reprocessEntity(sourceEntityIds);
  },
};
