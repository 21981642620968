import { ISchemaNode } from '../models/schema';

export const comparePossibleUndefinedProperty = (a: string, b: string) => {
  return a || b ? (!a ? -1 : !b ? 1 : a.localeCompare(b)) : 0;
};

// Small helper for detection of external links
export const isExternal = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d.?]([a-z\\d-.?]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '|^tel:|^mailto:',
    'i',
  ); // fragment locator
  return pattern.test(url);
};

export const tenantIdRegex =
  /gid:\/\/Tenant\/(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/;

export const formatDateTime = (date: string) => {
  const d = new Date(date);

  return (
    d.getDate().toString().padStart(2, '0') +
    '/' +
    (d.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    d.getFullYear() +
    ' ' +
    d.getHours().toString().padStart(2, '0') +
    ':' +
    d.getMinutes().toString().padStart(2, '0')
  );
};

export const camelize = (str: string) => {
  // Camelcase string
  str = str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

  // Remove non alpha chars
  const regex = /[^A-Za-z0-9]/g;
  return str.replace(regex, '');
};

export const hasSlash = (name: string) => name.includes('/');
export const startsWithSlash = (name: string) => name.charAt(0) === '/';
export const endsWithSlash = (name: string) => name.slice(-1) === '/';
export const repeatingSlash = (name: string) => name.includes('//');
export const moreThanTwoSlashes = (name: string) => name.split('/').length > 3;

export const schemaHasValidFolderName = (name: string) => {
  return (
    hasSlash(name) &&
    !(
      startsWithSlash(name) ||
      endsWithSlash(name) ||
      repeatingSlash(name) ||
      moreThanTwoSlashes(name)
    )
  );
};

export const sortFolderOverSchemas = (
  a: ISchemaNode,
  b: ISchemaNode,
  propertyName = 'name',
) => {
  if (!a.hasChildren) {
    return 1;
  }
  if (!b.hasChildren) {
    return -1;
  }
  return a.name.localeCompare(b[propertyName]);
};

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
