import { NuxtAxiosInstance } from '@nuxtjs/axios';
import qs from 'qs';
import { ILogsPayload } from '../models/logs';

export default ($axios: NuxtAxiosInstance) => ({
  getLogs(payload: ILogsPayload) {
    return $axios.get(`/api/v1/tenant/logs`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  },
});
