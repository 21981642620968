import { NuxtAxiosInstance } from '@nuxtjs/axios';
import qs from 'qs';

import {
  ISourceCreatePayload,
  ISourceEditPayload,
  ISourceReprocessEntity,
} from '../models/source';

import {
  ISourceEntitiesApiGetPayload,
  ISourceEntity,
} from '../models/sourceEntity';

import { CancelToken } from 'axios';
import { IApiResponse } from '../models/api';

export default ($axios: NuxtAxiosInstance) => ({
  getAll() {
    return $axios.get('/api/v1/tenant/sources');
  },
  create(payload: ISourceCreatePayload) {
    return $axios.post('/api/v1/tenant/sources', payload);
  },
  edit(id: string, payload: ISourceEditPayload) {
    return $axios.put('/api/v1/tenant/sources/' + id, payload);
  },
  delete(id: string) {
    return $axios.delete('/api/v1/tenant/sources/' + id);
  },
  getEntities(
    payload: ISourceEntitiesApiGetPayload,
    continuationToken?: string,
    cancelToken?: CancelToken,
  ) {
    const headers = continuationToken
      ? { 'x-continuation-token': continuationToken }
      : undefined;
    return $axios.get(`/api/v1/tenant/sources/${payload.id}/entities-list`, {
      cancelToken,
      headers,
      params: {
        type: payload.type,
        term: payload.term,
        typeTerm: payload.typeTerm,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  },
  getEntity(
    sourceId: string,
    entityId: string,
    format: 'raw' | 'placeholders' = 'placeholders',
  ) {
    return $axios.get(
      `/api/v1/tenant/sources/${sourceId}/entities/${entityId}`,
      {
        params: {
          format,
        },
      },
    );
  },
  deleteAllEntities(sourceId: string) {
    return $axios.delete('/api/v1/tenant/sources/' + sourceId + '/entities');
  },
  deleteEntitiesBulk(sourceId: string, entities: ISourceEntity[]) {
    return $axios.delete(`/api/v1/tenant/sources/${sourceId}/entities/_bulk`, {
      data: {
        bulk: entities.map((x) => {
          return { id: x.originId };
        }),
      },
    });
  },
  reprocessEntity(payload: ISourceReprocessEntity) {
    return $axios.post('/api/v1/tenant/source-entities/_process', payload);
  },
  getSourceEntityTypes(sourceId: string) {
    return $axios.$get(`/api/v1/tenant/sources/${sourceId}/entityTypes`);
  },
});
