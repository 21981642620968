import mixpanel from 'mixpanel-browser';
import { Context, Inject } from '@nuxt/types/app';
import { ISettingsResponse } from '../models/settings';

let platformVersion = '';

export default (ctx: Context, inject: Inject) => {
  if (ctx.app.$config.nodeEnv === 'production') {
    mixpanel.init(ctx.app.$config.mixpanelProjectToken);
  }

  inject('mixpanel', async (eventName: string, properties?: any) => {
    if (ctx.app.$config.nodeEnv !== 'production') {
      return;
    }

    if (!platformVersion) {
      const result = await fetch(`${ctx.app.$config.apiUrl}/api/settings`);
      const data: ISettingsResponse = await result.json();
      platformVersion = data.version;
    }

    const mixpanelProperties = {
      ...properties,
      platformVersion,
      appVersion: ctx.app.$config.appVersion,
      tenant: ctx.store.getters['tenant/current']?.name,
      isUsingSourceGroups:
        ctx.store.getters['tenant/current']?.isUsingSourceGroups,
      isUsingSchemasBulkDeployment:
        ctx.store.getters['tenant/current']?.isUsingSchemasBulkDeployment,
    };

    mixpanel.track(eventName, mixpanelProperties);
  });

  (ctx as any).app.router.afterEach((to: any, from: any) => {
    const visitedUrl = to.fullPath;
    const fromUrl = from.fullPath;

    // Removes duplicate events being fired due to initial redirects
    if (
      visitedUrl.startsWith('/authentication') ||
      fromUrl.startsWith('/authentication')
    ) {
      return;
    }

    ctx.app.$mixpanel(`Visited ${visitedUrl}`);
  });
};
