import { NuxtApp } from '@nuxt/types/app';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { AxiosError } from 'axios';
import NotificationType from '../constants/ui/notificationType';
import { displayNotification } from '../utils/ui/notification';

export default ({
  $axios,
  store,
  app,
}: {
  $axios: NuxtAxiosInstance;
  store: any;
  app: NuxtApp;
}) => {
  $axios.defaults.baseURL = app.$config.apiUrl;
  $axios.defaults.headers[
    'X-Enterspeed-System'
  ] = `management-app/${app.$config.appVersion}`;

  let isAlreadyFetchingAccessToken = false;
  let subscribers: any[] = [];

  $axios.interceptors.request.use(
    (config: any) => {
      const token = store?.getters['auth/accessToken'];
      const currentId = store?.getters['tenant/currentId'];

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (currentId) {
        config.headers['X-Tenant-Id'] = currentId;
      }
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    },
  );

  const onAccessTokenFetched = (accessToken: string) => {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach((callback) => callback(accessToken));
    subscribers = [];
  };

  const addSubscriber = (callback: any) => {
    subscribers.push(callback);
  };

  const handle401Refresh = async (response: any, shouldResolve?: boolean) => {
    const request = response.config;
    if ((request as any).__isRetryRequest) {
      return shouldResolve
        ? Promise.resolve(response)
        : Promise.reject(request);
    }
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
      since there another request that already attempt to
      refresh the token */
      addSubscriber((accessToken: string) => {
        request.headers.Authorization = `Bearer ${accessToken}`;
        (request as any).__isRetryRequest = true;
        resolve($axios(request));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      try {
        await store.dispatch('auth/AUTHENTICATE', true);
        const newToken = store.getters['auth/accessToken'];
        onAccessTokenFetched(newToken);
        isAlreadyFetchingAccessToken = false;
      } catch (error) {
        isAlreadyFetchingAccessToken = false;
        handle403();
        return shouldResolve
          ? Promise.resolve(response)
          : Promise.reject(request);
      }
    }
    return retryOriginalRequest;
  };

  const handle403 = () => {
    subscribers = []; // Clean the queue of requests
    store.dispatch('auth/SIGN_OUT');
  };

  $axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (!error.response) {
        return Promise.reject(error);
      }
      const { response } = error;
      try {
        if (
          response.status !== 401 ||
          (response.config as any).__isRetryRequest ||
          !store?.getters['auth/refreshToken']
        ) {
          let message = 'error';
          if (response?.data?.detail) {
            message = response.data.detail;
          } else if (response.data.title) {
            message = response.data.title;
          } else {
            message = response.status.toString();
          }

          // Avoid showing notification toast for failed releases
          if (response?.data?.code === 'FailedToCreateRelease') {
            store.dispatch(
              'bulk-deploy/SET_FAILED_RELEASES',
              response?.data?.errors,
            );
            return Promise.reject(error);
          }

          displayNotification(
            store,
            message,
            NotificationType.ERROR,
            response?.data?.errors,
          );

          return Promise.reject(error);
        }
        return handle401Refresh(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
};
